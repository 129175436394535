import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  deleteGoal,
  getPaginatedGoals,
  updateGoalHeader,
} from "../../service/goals/goalsService";
import { GoalDto } from "../../dtos/goals/GoalsDto";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import AddGoal from "./AddGoal";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { Link, useNavigate } from "react-router-dom";

import { Tooltip } from "primereact/tooltip";
import { GoalWithSkillsDto } from "../../dtos/goals/GoalWithSkillsDto";
import "../../css/components/goalTable.css";
import { Avatar } from "primereact/avatar";
import {
  createGoalInfo,
  deleteGoalInfo,
  editGoalInfo,
} from "../../shared/constant/PermissionVariables";
import { isPermissionGiven } from "../../shared/functions/HasPermission";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";
import bulbIcon from "../../images/svg/bulb.svg";
import { getBulbSvg } from "../../shared/functions/GetBlubIconSvg";

export interface LazyState {
  page: number;
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters?: string;
}

interface GoalErrors {
  Name: boolean;
  Duration: boolean;
}

interface GoalInfo {
  Name: string;
  Description: string;
  Duration: number;
}

const GoalTable = () => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );

  const dt = React.useRef<any>(null);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const dispatch = useDispatch();
  const row: GetPaginationRowsInterface = GetPaginationRowsCount();
  const [lazyState, setlazyState] = React.useState({
    page: 0,
    first: 0,
    rows: row.rowCount,
    sortField: "createdAt",
    sortOrder: 0 as any,
  });
  const [totalGoals, setTotalGoals] = useState(0);
  const [selectedGoal, setSelectedGoal] = useState<any>();
  const [goals, setGoals] = useState<GoalWithSkillsDto[] | []>([]);
  const toast = useRef<Toast>(null);
  const [showAddNewGoal, setShowAddNewGoal] = useState<boolean>(false);
  const [updateGoalDialog, setUpdateGoalDialog] = React.useState(false);
  const [goalId, setGoalId] = useState<number | null>(null);
  const [prevGoalName, setPrevGoalName] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const emptyGoal = {
    Name: "",
    Duration: 0,
    Description: "",
  };

  const emptyGoalErrors = {
    Name: false,
    Duration: false,
  };
  const [goalErrors, setGoalErrors] = useState<GoalErrors>(emptyGoalErrors);
  const [goalInfo, setGoalInfo] = useState<GoalInfo>(emptyGoal);
  const [showSkillDialog, setShowSkillDialog] = useState<boolean>(false);
  const [clickedShowSkillDialog, setClickedShowSkillDialog] = useState<
    number | null
  >(null);

  const [expandDescription, setExpandDescription] = useState<{
    [key: number]: boolean;
  }>({});
  const userPermissions = useSelector(
    (state: any) => state.permission.permissions
  );
  const navigate = useNavigate()
  //#endregion
  //#region all functions

  const onSort = (event: any) => {
    setlazyState((prev: LazyState) => {
      const newState: LazyState = {
        ...prev, // Copy all properties from the previous state
        sortOrder: event.sortOrder, // Update sortOrder
        sortField: event.sortField, // Update sortField
      };
      return newState;
    });
  };

  const onPageChange = (event: any) => {
    setlazyState(event);
  };
  const showSkillsDialogHandler = (rowData: any) => {
    setShowSkillDialog(true);
    setClickedShowSkillDialog(rowData.id);
  };
  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const accept = async (id: number) => {
    await deleteGoal(dispatch, id);

    await allDataFunc();

    showToast("success", "Goal  deleted successfully", "Success");
  };

  const reject = () => {
    showToast("warn", "You have rejected the delete operation", "Rejected");
  };

  const resetDialog = () => {
    setUpdateGoalDialog(false);
    setGoalInfo(emptyGoal);
    setGoalErrors(emptyGoalErrors);
    setPrevGoalName(null);
    setGoalId(null);
  };

  const allDataFunc = async () => {
    let param;

    if (searchText.length > 0) {
      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? "ASC" : "DESC",
        SortProperty: lazyState.sortField,
        Filter: searchText,
        PropertyNames: ["Name"],
      };
    } else {
      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? "ASC" : "DESC",
        SortProperty: lazyState.sortField,
      };
    }

    const paginatedGoals = await getPaginatedGoals(dispatch, param);
    setTotalGoals(paginatedGoals.totalCount);
    setGoals(paginatedGoals.data);
  };

  const descriptionExpandHandler = () => {
    const tempDescriptionExpandInfo = { ...expandDescription };
    if (goals) {
      goals.forEach((goal) => {
        tempDescriptionExpandInfo[goal.id] = false;
      });
    }
    setExpandDescription(tempDescriptionExpandInfo);
  };

  const editGoal = (rowData: any) => {
    setPrevGoalName(rowData.Name);
    setGoalId(rowData.id);
    setUpdateGoalDialog(true);
    setGoalInfo({
      Name: rowData.Name,
      Duration: rowData.Duration,
      Description: rowData.Description,
    });
  };

  const onGoalChange = (name: string, value: string | number) => {
    setGoalErrors({ ...goalErrors, [name]: false });
    setGoalInfo((prevGoal) => ({
      ...prevGoal,
      [name]: value,
    }));
  };

  const handleCreateClick = () => {
    const hasPermission = isPermissionGiven(createGoalInfo, userPermissions, dispatch);

    if (hasPermission) {
      // If the user has permission, navigate to the create page
      navigate(`/updateGoal/${Date.now()}`);
    }
  };
  const handleEditClick = (rowData: any) => {
    const hasPermission = isPermissionGiven(editGoalInfo, userPermissions, dispatch);

    if (hasPermission) {
      // If the user has permission, navigate to the create page
      navigate(`/updateGoal/${rowData.id}`);
    }
  };

  //#endregion

  //#region all templates
  const confirm2 = (id: number) => {
    confirmDialog({
      message: (
        <div>
          <p>Do you really want to delete this {"goal"}?</p>
        </div>
      ),
      header: `${localization?.DeleteConfirmation || "Delete Confirmation"}`,

      className: "del_modal",
      headerStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      style: {
        width: "350px",
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      contentStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      acceptClassName: "p-button-danger",
      accept: () => accept(id),
      reject,
    });
  };

  const deleteGoalById = (rowData: GoalDto) => {
    confirm2(rowData.id);
  };

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.Goals || "Goals"}</h4>
      </div>
    );
  };

  const endToolbar = () => {
    return (
      <div className="endToolbar">

        {userPermissions.includes(createGoalInfo) && <Button
          className="justify-content-center"
          label={localization?.Create || "Create"}
          onClick={handleCreateClick}
          style={{
            backgroundColor: ThemeColors?.primaryColor,
            border: "none",
          }}
        />}
        <div className="searchBxCont">
          <div className="searchBx border_primary">
            <InputText
              className="w-100 border-0"
              type="search"
              placeholder={` ${localization?.Search || "Search"}...`}
              onChange={(e) => {
                setTimeout(() => {
                  setSearchText(e.target.value);
                }, 500);
              }}
            />
            <span className="searchIcon"><i className="pi pi-search"></i></span>
          </div>
        </div>
      </div>
    );
  };

  const editDeleteButtonTemplate = (rowData: any) => {
    return (
      <div className="editDeleteButtonTemplate">

        {userPermissions.includes(editGoalInfo) && <Button
          icon="pi pi-pencil"
          // label={localization?.Edit || "Edit"}
          className="editDelButton editBtn"
          style={{ backgroundColor: ThemeColors?.primaryColor }}
          onClick={() => handleEditClick(rowData)}
        />}

        {userPermissions.includes(deleteGoalInfo) && <Button
          icon="pi pi-trash"
          // label={localization?.Delete || "Delete"}
          className="editDelButton delBtn"
          onClick={() => {
            if (isPermissionGiven(deleteGoalInfo, userPermissions, dispatch)) {
              deleteGoalById(rowData);
            }
          }}
          style={{ backgroundColor: "#d80300" }}
        />}
      </div>
    );
  };

  const dialogHeader = () => {
    return (
      <>
        <div className="dialogHeader">
          <h4>{localization?.EditGoal || "Edit Goal"}</h4>
        </div>
      </>
    );
  };

  const goalDescriptionTemplate = (rowData: any) => {
    return (
      <>
        {rowData.Description.length > 150 ? (
          <>
            {rowData.Description.slice(0, 150)}
            <span
              className={`goal-description-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.goal-description-expanded-dots${rowData.id}`}
                content={rowData.Description}
                // style={{ width: "30rem" }}
                className="w-75 w-md-200px"

              />
            </span>
          </>
        ) : (
          rowData.Description
        )}
      </>
    );
  };

  const goalNameTemplate = (rowData: any) => {
    return (
      <>
        {rowData.Name.length > 70 ? (
          <>
            {rowData.Name.slice(0, 70)}
            <span
              className={`name-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.name-expanded-dots${rowData.id}`}
                content={rowData.Name}
                style={{ width: "50rem" }}
              />
            </span>
          </>
        ) : (
          rowData.Name
        )}
      </>
    );
  };

  const dialogFooter = () => {
    return (
      <div
        style={{
          padding: "1rem 0",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      >
        <Button
          label={localization?.Save || "Save"}
          className="kpi-save-button"
          // onClick={handleSave}
          style={{ backgroundColor: ThemeColors?.primaryColor }}
        />
      </div>
    );
  };

  const skillTemplate = (rowData: any) => {
    return (
      <div>

        <div className="d-flex gap-2 align-items-center justify-content-center">
          <Avatar
            className="p-overlay-badge avatar-content1 skill-icon skill-avatar bg-transparent"
            onClick={() => {
              rowData?.Skills?.length > 0 && showSkillsDialogHandler(rowData);
            }}
            style={{ cursor: rowData?.Skills?.length ? "pointer" : "default" }}
          >
            <img
              src={bulbIcon}
              alt="Icon"
              style={{
                filter: rowData.Skills.length > 0 ? undefined : "grayscale(1)",
              }}
            />
          </Avatar>
          <span className="bg-transparent text-danger">
            {rowData?.Skills?.length > 0
              ? `(${rowData?.Skills?.length})`
              : `(0)`}
          </span>
        </div>
        {showSkillDialog && clickedShowSkillDialog === rowData.id && (
          <Dialog
            header={localization?.Skills || "Skills"}
            visible={showSkillDialog}
            style={{
              width: "30vw",
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            contentStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            onHide={() => {
              if (!showSkillDialog) return;
              setShowSkillDialog(false);
              setClickedShowSkillDialog(null);
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {rowData.Skills.length > 0
                ? rowData.Skills.map((skill: { SkillName: string }) => {
                  return (
                    <div
                      className="avatar-container d-flex flex-column align-items-center justify-content-start"
                      style={{ marginRight: "14px" }}
                    >
                      <div className="skillAvatar"

                      >
                        <span className="position-relative">
                          {getBulbSvg("black")}
                        </span>
                      </div>
                      <p
                        className="skill-text"
                        style={{
                          wordWrap: "break-word", // Ensure long words break to the next line
                          width: '95px',
                          overflowWrap: "break-word", // Ensures text wrapping for older browsers
                          textAlign: "center", // Optional for alignment
                        }}
                      >{skill.SkillName}</p>
                    </div>
                  );
                })
                : localization?.NoSkillsAvailableForThisGoal ||
                "No skills available for this goal"}
            </div>
          </Dialog>
        )}
      </div>
    );
  };
  //#endregion
  useEffect(() => {
    descriptionExpandHandler();
  }, [goals]);

  useEffect(() => {
    allDataFunc();
  }, [lazyState, searchText]);

  return (
    <div className="goalTableCont">
      <Toast ref={toast} />

      <ConfirmDialog />
      <Toolbar
        className="bg-transparent border-0 p-0 mb-3"
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      />

      <div
        // style={{ backgroundColor: mode?.backgroundSecondaryColor }}
        className="goalTableBX"
      >
        <DataTable
          className="goalTable"
          ref={dt}
          value={goals}
          selection={selectedGoal}
          onSelectionChange={(e: any) => setSelectedGoal(e.value)}
          dataKey="id"
          lazy
          paginator
          first={lazyState.first}
          rows={lazyState.rows}
          totalRecords={totalGoals}
          onSort={onSort}
          onPage={onPageChange}
          sortOrder={lazyState.sortOrder}
          sortField={lazyState.sortField}
          showGridlines
          rowsPerPageOptions={row.rowList}
          currentPageReportTemplate="Total Records {totalRecords} "
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            className="kpiColumn"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
              minWidth: "200px",
            }}
            field="Name"
            body={goalNameTemplate}
            header={localization?.GoalName || "Goal Name"}
            sortable
          ></Column>
          <Column
            className="kpiColumn"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="Description"
            body={goalDescriptionTemplate}
            header={localization?.Description || "Description"}
          ></Column>
          <Column
            className="kpiColumn col_center col_150"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              textAlign: "center",
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="Duration"
            header={`${localization?.Duration || "Duration"} (${localization?.Days || "Days"
              })`}
            sortable
          ></Column>
          <Column
            className="kpiColumn col_center col_150"
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              textAlign: "center",
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            field="Skills.SkillName"
            header={`${localization?.Skills || "Skills"}`}
            body={skillTemplate}
          ></Column>
          {(userPermissions.includes(editGoalInfo) || userPermissions.includes(deleteGoalInfo)) && <Column
            className="kpiColumn col_center actionColumn"
            bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={editDeleteButtonTemplate}
            header={localization?.Action || "Action"}
          ></Column>}
        </DataTable>
      </div>
      <Dialog
        visible={updateGoalDialog}
        header={dialogHeader}
        footer={dialogFooter}
        style={{
          width: "50vw",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        baseZIndex={999}
        onHide={resetDialog}
        className="dialog"
        draggable={false}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            {localization?.GoalName || "Goal Name*"}
          </label>
          <InputText
            id="goal-name"
            name="Name"
            value={goalInfo.Name}
            onChange={(e) => onGoalChange(e.target.name, e.target.value)}
          />

          {goalErrors.Name && (
            <small className="p-error">Goal Name is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            {localization?.Duration || "Duration*"}
          </label>
          <InputNumber
            inputId="integeronly"
            value={goalInfo.Duration}
            name="Duration"
            onChange={(e) => {
              onGoalChange("Duration", e.value!);
            }}
            min={0}
            onInput={(e: any) => {
              onGoalChange("Duration", parseInt(e.target.value));
            }}
          />
          {goalErrors.Duration && (
            <small className="p-error">Duration is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="information" className="font-bold">
            {localization?.GoalDescription || "Goal Description"}
          </label>
          <InputTextarea
            className="custom-textarea"
            id="goal-description"
            name="Description"
            value={goalInfo.Description}
            onChange={(e) => onGoalChange(e.target.name, e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
      </Dialog>

      <AddGoal
        showAddNewGoal={showAddNewGoal}
        setShowAddNewGoal={setShowAddNewGoal}
        setSelectedGoal={setSelectedGoal}
        allDataFunc={allDataFunc}
      />
    </div>
  );
};

export default GoalTable;
