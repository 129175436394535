import ReactApexChart from "react-apexcharts"
import { TopPerformingEmp } from "../../dtos/dashboard/TopPerformingEmp"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Themes } from "../../data/Themes"
import { ThemeState } from "../../dtos/common/ThemeState"
import ReactStars from "react-stars"
import { AppMode } from "../../data/AppMode"
import { AppModeState } from "../../dtos/common/AppModeState";
import { HighStarColor, LowStarColor, LowStarVal, MidStarColor, MidStarVal } from "../../shared/constant/StarValue"
import Rating from '@mui/material/Rating';
import { getRandomColor } from "../../shared/functions/GetRandomColour"

interface props {
    Data: TopPerformingEmp[]
    formatInitials: (fullName: string) => string;
}

export const EmployeePerformanceChart = (props: props) => {
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find((th) => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find((md) => md.name === modeName);
    let series: number[] = [];
    let topPerforming: TopPerformingEmp[] = []
    let midPerforming: TopPerformingEmp[] = []
    let lowPerforming: TopPerformingEmp[] = [];
    const [dataLength, setDataLength] = useState(0);
    const [dataLabel, setDataLabel] = useState('Top Performing Employee');
    const [chartData, setChartData] = useState<TopPerformingEmp[]>([]);
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(400);
    const screenWidth = window.innerWidth;
    const [ratingComp, setRatingComp] = useState<any>();

    props?.Data?.forEach(val => {
        const rating = Number(val.Rating.toFixed(2))
        if (rating > MidStarVal) {
            topPerforming.push(val)
        }
        else if (rating <= LowStarVal) {
            lowPerforming.push(val)
        }
        else if (rating > LowStarVal && rating < MidStarVal) {
            midPerforming.push(val)
        }
    })

    series = [topPerforming?.length, midPerforming?.length, lowPerforming?.length]


    const state: any = {

        series: series,
        options: {
            chart: {
                width: '100%',
                type: 'pie',
                events: {
                    dataPointSelection: (event: any, chartContext: any, config: any) => {

                        const dataLength = config.w.config.series[config.dataPointIndex]
                        const label = config.w.config.labels[config.dataPointIndex];

                        setDataLength(dataLength)
                        setDataLabel(label)
                    }
                }
            },
            legend: {
                position: 'top',
                labels: {
                    colors: mode?.color
                }
            },
            labels: ['Top Performed Employee', 'Mid Performed Employee', 'Low Performed Employee'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],

            colors: [HighStarColor, MidStarColor, LowStarColor, '#7396ff'],
        },


    };

  
      

    const handleChartData = () => {

        if (dataLength === topPerforming.length && dataLabel === 'Top Performed Employee') {
            setChartData(topPerforming);
            return
        }
        if (dataLength === midPerforming.length && dataLabel === 'Mid Performed Employee') {
            setChartData(midPerforming)
            return
        }
        if (dataLength === lowPerforming.length && dataLabel === 'Low Performed Employee') {
            setChartData(lowPerforming)
            return
        }
    }

    const handleDefaultData = () => {
        if (topPerforming.length > 0) {
            setChartData(topPerforming)
            setDataLabel('Top Performed Employee');
            return;
        }

        if (midPerforming.length > 0) {
            setChartData(midPerforming)
            setDataLabel('Mid Performed Employee');
            return;
        }

        if (lowPerforming.length > 0) {
            setChartData(lowPerforming)
            setDataLabel('Low Performed Employee');
            return;
        }
    }

    const getStarColor = (val: number) => {
        let color;
        if (val <= LowStarVal) {
            color = LowStarColor;
        }

        if (val > LowStarVal && val < MidStarVal) {
            color = MidStarColor;
        }

        if (val > MidStarVal) {
            color = HighStarColor;
        }
        return color
    }

    useEffect(() => {
        handleChartData()
    }, [dataLength, dataLabel])

    useEffect(() => {

        handleDefaultData()
        if (props.Data.length === 0) {
            setDataLabel('')
        }

    }, [props.Data])

    useEffect(() => {
        //for width maintaining
        if (screenWidth < 2561) {
            // setWidth(320)
            setWidth(340)
        }
        if (screenWidth < 1400) {
            // setWidth(350)
            setWidth(320)
        }
        if (screenWidth < 1300) {
            // setWidth(300)
            setWidth(300)
        }
        if (screenWidth < 1200) {
            setWidth(270)
        }
        if (screenWidth < 991) {
            setWidth(450)
        }
        if (screenWidth < 767) {
            setWidth(500)
        }
        if (screenWidth < 575) {
            setWidth(350)
        }
    }, [])


    return (
        <div className="emp-performance-chart">
            {props.Data.length > 0 ?  <div className="empPerformanceChartBx">
                <ReactApexChart options={state.options} series={state.series} type="pie" width={width}  />
                </div>
                :
                <div style={{ color: mode?.color }} className="no_employee">
                    No data to show
                </div>}

            {props.Data.length > 0 ?
                <div className="emp-per-chart-box">
                    <h3 className="dashboard-card-head">{dataLabel}</h3>
                    <div className="list_item TPE_lightbg p-4">
                        <ul>
                        {
                            chartData?.map((val, i) => (
                                    <li key={i}>
                                        <div className="emp-per-chart-data d-flex align-items-start gap-1">
                                            <div className="w-3rem h-3rem flex align-items-center justify-content-center  border-circle flex-shrink-0 me-3" style={{backgroundColor:getRandomColor(),color:"#fff"}}>
                                                <span className="text-xl fw-bold" style={{}}>{props.formatInitials(val.EmployeeName)}</span>
                                            </div>
                                            <div className="d-flex flex-1 flex-wrap align-items-center justify-content-between gap-2">
                                                <div className="d-flex flex-column">
                                                    <p className="text-base fs-6 fs-lg-5"><span>Name:</span> {val.EmployeeName}</p>
                                                    <div className="d-flex flex-wrap gap-1">
                                                        <p className="text-danger me-3 fs-8"><span>Rating:</span> {val.Rating.toFixed(2)}</p>
                                                        <p className="text-secondary fs-8"><span>Code:</span> {val.EmployeeCode}</p>
                                                    </div>
                                                </div>

                                                <div className="empChart_rating d-flex">
                                                    <Rating name="half-rating-read" style={{ color: getStarColor(Number(val.Rating.toFixed(2))) }} value={Number(val.Rating.toFixed(2))} precision={0.5} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                
                            ))
                        }
                        </ul>
                        
                        
                    </div>
                </div> :
                <div style={{ color: mode?.color }} className="no_employee">No Employee To Show</div>}
        </div>
    )
}