// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assign-to-employees-header {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
}

.assign-to-employees-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px 0px;
}
.assign-to-employees-table  > tr th {
  background-color: var(--blue-highlightbtn) !important;
  color: var(--bs-white) !important;
}
.assign-to-employees-table  > tr th:first-child {
  border-top-left-radius: 7px;
}
.assign-to-employees-table  > tr th:nth-last-of-type(1) {
  border-top-right-radius: 7px;
}
.light .assign-to-employees-table  > tr:nth-of-type(n) td {
  background-color: #f2f2f2 !important;
}

@media only screen and (max-width: 575px) {
  .assign-to-employees-table {
    width: 140%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/components/assignToEmployees.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,iCAAiC;EACjC,kCAAkC;EAClC,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,qDAAqD;EACrD,iCAAiC;AACnC;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".assign-to-employees-header {\n  display: flex !important;\n  flex-direction: column !important;\n  justify-content: center !important;\n  align-items: flex-start !important;\n}\n\n.assign-to-employees-table {\n  width: 100%;\n  border-collapse: separate;\n  border-spacing: 1px 0px;\n}\n.assign-to-employees-table  > tr th {\n  background-color: var(--blue-highlightbtn) !important;\n  color: var(--bs-white) !important;\n}\n.assign-to-employees-table  > tr th:first-child {\n  border-top-left-radius: 7px;\n}\n.assign-to-employees-table  > tr th:nth-last-of-type(1) {\n  border-top-right-radius: 7px;\n}\n.light .assign-to-employees-table  > tr:nth-of-type(n) td {\n  background-color: #f2f2f2 !important;\n}\n\n@media only screen and (max-width: 575px) {\n  .assign-to-employees-table {\n    width: 140%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
