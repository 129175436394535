export const formatInitials = (fullName: string) => {
  // Split the name into parts (assuming it's separated by spaces)
  const nameParts = fullName.split(" ");

  // Take the first and last parts (if available)
  const initials = nameParts
    .filter((_, index) => index === 0 || index === nameParts.length - 1) // Keep only the first and last parts
    .map((part) => part.charAt(0).toUpperCase()) // Get the first letter and capitalize it
    .join(""); // Join them together to get the initials

  return initials;
};
