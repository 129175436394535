export const Themes = [
    {
        name:'blue',
        fontColor: "white",
        // primaryColor: "#0355A0",
        // secondaryColor: "#1E7FD9"
        primaryColor: "#2E6198",
        secondaryColor: "#4075AE",
        tertiaryColor: "#DDEEFF"
    },
    {
        name:'violet',
        fontColor: "white",
        primaryColor: "#551fb3",
        secondaryColor: "#7b45d9"
    },
    {
        name: 'green',
        primaryColor: '#407053',
        secondaryColor: '#5CA177',
        fontColor: 'white'

    },
    {
        name: 'maroon',
        primaryColor: '#652524' ,
        secondaryColor:'#9B4444',
        fontColor: 'white'

    }
]