import { useEffect, useRef, useState } from "react";
// import "primereact/resources/themes/lara-light-indigo/theme.css"; //primereact-theme
import "primereact/resources/primereact.min.css"; //primereact-core
import "primeflex/primeflex.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ProtectedRoute from "./utills/ProtectedRoute";
import "./App.css";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import instance from "./utills/BaseService";
import { useDispatch, useSelector } from "react-redux";
import { setLocalization } from "./store/slices/localizationSlice";
import { PerformanceTable } from "./components/performance/PerformanceTable";
import "primeicons/primeicons.css";
import { KpiMappings } from "./components/kpiMappings/KpiMappings";
import { EmployeeBilling } from "./components/employeeBilling/EmployeeBilling";
import { EditProfile } from "./components/editProfile/EditProfile";
import { KPITable } from "./components/KPI/KPITable";
import { ProjectBilling } from "./components/projectBilling/ProjectBilling";
import { Project } from "./components/project/Project";
import { Employee } from "./components/employee/Employee";
import { Dashboard } from "./components/dashboard/DashBoard";
import { Report } from "./components/report/Report";
import { PerformnaceView } from "./components/subComponents/PerformanceView";
import { EmailSettings } from "./components/settings/EmailSettings";
import ProtectAdminRoutes from "./utills/ProtectAdminRoutes";
import { SystemAdmin } from "./components/settings/SystemAdmin";
import { CronSettings } from "./components/settings/CronSettings";
import { ApplicationSettings } from "./components/settings/ApplicationSettings";
import { YourPerformance } from "./components/yourPerformance/YourPerformance";
import { ErrorBoundary } from "react-error-boundary";
import { UnhandledError } from "./components/error/UnhandledError";
import { PageNotFound } from "./components/error/PageNotFound";
import { MailTemplate } from "./components/settings/MailTemplate";
import { AppModeState } from "./dtos/common/AppModeState";
import { ThemeState } from "./dtos/common/ThemeState";
import { UserMappings } from "./components/userMappings/UserMappings";
import { PerformanceReport } from "./components/performanceReport/PerformanceReport";
import { AuditLog } from "./components/settings/AuditLog";
import "primeicons/primeicons.css";
import { CronJobData } from "./components/settings/CronJobData";
import { CronJobStatus } from "./components/settings/CronJobStatus";
import SubKpi from "./components/subKpi/SubKpi";
import SubKpiOptions from "./components/subKpiOptions/SubKpiOptions";
import Goals from "./components/goals/Goals";
import UserGoal from "./components/userGoal/UserGoal";
import UpdateGoalTable from "./components/goals/UpdateGoalTable";
import GoalTable from "./components/goals/GoalTable";
import SkillTable from "./components/skills/SkillTable";
import RoleTable from "./components/roles/RolesTable";
import UserTable from "./components/users/UserTable";
import {
  appSettings,
  auditLog,
  cronJobData,
  cronJobStatus,
  cronSettings,
  dashboard,
  employeeBilling,
  goals,
  indivisualRole,
  kpi,
  kpiMappings,
  mailSettings,
  mailTemplate,
  myGoals,
  myPerformance,
  myTeam,
  options,
  performanceReport,
  project,
  projectBilling,
  ratingsAndReviews,
  report,
  skills,
  subKPI,
  systemAdmin,
  userGoals,
  userMapping,
  userPermission,
} from "./shared/constant/PermissionVariables";
import { PermissionDenied } from "./components/error/PermissionDenied";
import { setPermissions } from "./store/slices/permissionSlice";
import {  getSpecificPermission } from "./service/permissionAssign/permissionAssignServices";
import { getUserRole } from "./service/user/userService";
import Loader from "./components/loader/Loader";
import PermissionRoutes from "./utills/PermissionRoutes";

function App() {
  const dispatch = useDispatch();
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const userPermissions = useSelector(
    (state: any) => state.permission.permissions
  );
  const SettingsHandler = async () => {
    const setting = await instance.get("/configuration/getallappsetting");
    dispatch(setLocalization(setting.data.values));
  };

  const getPermission = async () => {
   
  
    const alreadyGivenPermissions = await getSpecificPermission(dispatch);
    const grantedPermissions = alreadyGivenPermissions?.filter((node)=>{
      return node.IsGranted
    })
    const permissions =
      grantedPermissions?.map(
        (permission: any) => permission.PermissionName
      ) || [];

    dispatch(setPermissions(permissions));
  };

  // Clear localStorage only if navigating to login due to permissions
  useEffect(() => {
    if (location.pathname === "/login") {
      localStorage.clear();
    }
  }, [location]);

     useEffect(() => {
    if (modeName==="dark") {
      import("primereact/resources/themes/lara-dark-blue/theme.css" as any)
    } else {
      import("primereact/resources/themes/lara-light-blue/theme.css" as any)
    }
  }, [modeName]);

  
  


  useEffect(() => {
    const fetchPermissionsAndRunCode = async () => {
      setLoading(true);
      await getPermission(); // Wait for getPermission to complete
      setLoading(false);
      console.log("Permissions are set, now running other code...");
    };

    fetchPermissionsAndRunCode();
  }, []);

  useEffect(() => {
    SettingsHandler();
  }, []);

  return (
    <div className={`${modeName} ${themeName}`}>
      <ErrorBoundary fallback={<UnhandledError />}>
        <Routes>
          {loading && location.pathname !== "/login" ? (
            <Route
              path="*"
              element={
                <div className="loading-overlay">
                  <Loader />
                </div>
              }
            />
          ) : (
            <>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              >
                <Route
                  path="kpi"
                  element={
                    <ProtectAdminRoutes permissionName={kpi}>
                      <KPITable />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="/option"
                  element={
                    <ProtectAdminRoutes permissionName={options}>
                      <SubKpiOptions />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="/kpi/:kpiId"
                  element={
                    <ProtectAdminRoutes permissionName={subKPI}>
                      <SubKpi />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="/performance/*"
                  element={
                    <ProtectAdminRoutes permissionName={ratingsAndReviews}>
                      <PerformanceTable />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="/performance/:empId"
                  element={
                    <ProtectAdminRoutes permissionName={ratingsAndReviews}>
                      <PerformnaceView />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="kpiMappings"
                  element={
                    <ProtectAdminRoutes permissionName={kpiMappings}>
                      <KpiMappings />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="userMappings"
                  element={
                    <ProtectAdminRoutes permissionName={userMapping}>
                      <UserMappings />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="employeeBilling"
                  element={
                    <PermissionRoutes permissionName={employeeBilling}>
                      <EmployeeBilling />
                    </PermissionRoutes>
                  }
                />
                <Route path="editprofile" element={<EditProfile />} />
                <Route
                  path="projectBilling"
                  element={
                    <PermissionRoutes permissionName={projectBilling}>
                      <ProjectBilling />
                    </PermissionRoutes>
                  }
                />
                {/* <Route path="project" element={<Project />} /> */}
                <Route
                  path="project"
                  element={
                    <PermissionRoutes permissionName={project}>
                      <Project />
                    </PermissionRoutes>
                  }
                />

                <Route
                  path="employee"
                  element={
                    <PermissionRoutes permissionName={myTeam}>
                      <Employee />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="yourPerformance"
                  element={
                    <PermissionRoutes permissionName={myPerformance}>
                      <YourPerformance />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="dashboard"
                  element={
                    <PermissionRoutes permissionName={dashboard}>
                      <Dashboard />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="report"
                  element={
                    <PermissionRoutes permissionName={report}>
                      <Report />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="ratingsReport"
                  element={
                    <PermissionRoutes permissionName={performanceReport}>
                      <PerformanceReport />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="goals"
                  element={
                    <PermissionRoutes permissionName={goals}>
                      <GoalTable />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="userGoals"
                  element={
                    <PermissionRoutes permissionName={userGoals}>
                      <Goals />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="myGoals"
                  element={
                    <PermissionRoutes permissionName={myGoals}>
                      <UserGoal />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="updateGoal/:id"
                  element={
                    <PermissionRoutes permissionName={goals}>
                      <UpdateGoalTable />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="skills"
                  element={
                    <PermissionRoutes permissionName={skills}>
                      <SkillTable />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="employeeRole"
                  element={
                    <PermissionRoutes permissionName={indivisualRole}>
                      <RoleTable />
                    </PermissionRoutes>
                  }
                />
                <Route
                  path="users"
                  element={
                    <PermissionRoutes permissionName={userPermission}>
                      <UserTable />
                    </PermissionRoutes>
                  }
                />

                <Route
                  path="applicationSettings"
                  element={
                    <ProtectAdminRoutes permissionName={appSettings}>
                      <ApplicationSettings />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="mailSettings"
                  element={
                    <ProtectAdminRoutes permissionName={mailSettings}>
                      <EmailSettings />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="systemAdmin"
                  element={
                    <ProtectAdminRoutes permissionName={systemAdmin}>
                      <SystemAdmin />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="cronSettings"
                  element={
                    <ProtectAdminRoutes permissionName={cronSettings}>
                      <CronSettings />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="mailTemplateSettings"
                  element={
                    <ProtectAdminRoutes permissionName={mailTemplate}>
                      <MailTemplate />
                    </ProtectAdminRoutes>
                  }
                />
                {/* <Route path='projectSettings' element={<ProtectAdminRoutes><ProjectSettings /></ProtectAdminRoutes>} /> */}
                <Route
                  path="auditLog"
                  element={
                    <ProtectAdminRoutes permissionName={auditLog}>
                      <AuditLog />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="cronJobDataSettings"
                  element={
                    <ProtectAdminRoutes permissionName={cronJobData}>
                      <CronJobData />
                    </ProtectAdminRoutes>
                  }
                />
                <Route
                  path="cronJobStatusSettings"
                  element={
                    <ProtectAdminRoutes permissionName={cronJobStatus}>
                      <CronJobStatus />
                    </ProtectAdminRoutes>
                  }
                />
              </Route>

              <Route path="*" element={<PageNotFound />} />
              <Route path="/permissionDenied" element={<PermissionDenied />} />
              <Route path="/login" element={<Login />} />
            </>
          )}
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

export default App;
