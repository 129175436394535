import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { Toolbar } from "primereact/toolbar";
import "../../css/components/addGoal.css";
import growingUser from '../../images/Goal-bro.png'
import {
  checkIfGoalExist,
  createGoalSteps,
  createGoalWithSteps,
  deleteGoal,
  deleteGoalStep,
  getAllPaginatedGoalSteps,
  getGoalByGoalName,
  getGoalById,
  getGoalStepByStepNameAndGoalName,
  updateGoalHeader,
  updateGoalStep,
} from "../../service/goals/goalsService";
import { useParams } from "react-router-dom";
import { NotAssignedGoalDto } from "../../dtos/goals/NotAssignedGoalDto";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "primereact/tooltip";
import { SkillDto } from "../../dtos/skill/SkillsDto";
import { createSkill, getAllSkills } from "../../service/skill/skillServices";
import { GoalSkillDto } from "../../dtos/goalSkill/GoalSkillDto";
import ModifiedMultiselect from "../modifiedMultiselect/ModifiedMultiselect";
import { createGoalStep, deleteGoalStepInfo, editGoalStepInfo, goalStep } from "../../shared/constant/PermissionVariables";
import { isPermissionGiven } from "../../shared/functions/HasPermission";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";

interface rowData {
  id: number;
  Name: string;
  Description: string;
  Duration: number;
  GoalId: number;
}

export interface LazyState {
  page: number;
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters?: string;
}

interface GoalInfo {
  Name: string;
  Description: string;
  Duration?: number;
  Score?: number;
  OrderNumber?: number;
  GoalName?: string;
  GoalId?: number;
}

interface onlyGoalInfo {
  id: number;
  Name: string;
  Duration: number;
  Description: string;
}

interface GoalErrors {
  Name: boolean;
  Duration?: boolean;
  Score?: boolean;
}

interface GoalWithSkillErrors {
  GoalName: boolean;
  Duration: boolean;
  Skills: boolean;
}

const UpdateGoalTable = () => {
  //#region all Variables
  const { id } = useParams();
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const [searchText, setSearchText] = React.useState<string>("");
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const dispatch = useDispatch();
  const [goal, setGoal] = useState<NotAssignedGoalDto>();
  const dt = React.useRef<any>(null);
  const [selectedKpi, setSelectedKpi] = React.useState(undefined);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [updateGoalDialog, setUpdateGoalDialog] = React.useState(false);
  const toast = useRef<Toast>(null);
  const [create, setCreate] = useState<boolean>(false);
  const [stepId, setStepId] = useState<number | null>(null);
  const [prevStepName, setPrevStepName] = useState<string>("");
  const [totalGoalSteps, setTotalGoalSteps] = useState<number>(0);
  const [onlyGoalInfo, setOnlyGoalInfo] = useState<onlyGoalInfo>();
  const navigate = useNavigate();
  const isEditOrCreate = edit || create;
  const [selectedSkills, setSelectedSkills] = useState<SkillDto[] | []>([]);
  const [allSkills, setAllSkills] = useState<SkillDto[]>([]);
  const [goalWithSkillsError, setGoalWithSkillError] =
    useState<GoalWithSkillErrors>({
      GoalName: false,
      Duration: false,
      Skills: false,
    });
  const [goalWithSkills, setGoalWithSkills] = useState<{
    GoalName: string;
    GoalDescription: string;
    Duration: number;
    Skills: { id: number; Name: string }[] | [];
  }>({
    GoalName: "",
    GoalDescription: "",
    Duration: 0,
    Skills: [],
  });
  const [isCreateClicked, setIsCreateClicked] = useState<boolean>(false);

  const isInitialRender = useRef(true);

  const emptyGoal = {
    Name: "",
    Duration: 0,
    Description: "",
    Score: 0,
    GoalName: "",
    GoalId: 0,
  };

  const emptyGoalErrors = {
    Name: false,
    Duration: false,
    Score: false,
  };
  const row: GetPaginationRowsInterface = GetPaginationRowsCount();
  const [lazyState, setlazyState] = React.useState({
    page: 0,
    first: 0,
    rows: row.rowCount,
    sortField: "id",
    sortOrder: 1 as any,
  });

  const [goalInfo, setGoalInfo] = useState<GoalInfo>(emptyGoal);
  const [goalErrors, setGoalErrors] = useState<GoalErrors>(emptyGoalErrors);

  const [isItemToExpand, setIsItemToExpand] = useState<{
    [key: number]: boolean;
  }>({});

  const [inputValue, setInputValue] = useState<string>("");
  const userPermissions = useSelector(
    (state: any) => state.permission.permissions
  );
  //#endregion

  //#region all Functions

  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const editGoalStep = (rowData: any) => {
      const hasPermission = isPermissionGiven(editGoalStepInfo, userPermissions, dispatch); 
      if(!hasPermission){
        return 
      }
   
    setPrevStepName(rowData.Name);
    setStepId(rowData.id);
    setUpdateGoalDialog(true);
    setEdit(true);
    setGoalInfo({
      Name: rowData!.Name,
      Score: rowData!.Score,
      Description: rowData!.Description,
      OrderNumber: rowData!.OrderNumber,
      GoalName: rowData!.GoalName,
      GoalId: rowData!.GoalId,
    });
  };

  const allDataFunc = async (goalInfo: any) => {
    let param;

    if (searchText.length > 0) {
      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? "ASC" : "DESC",
        SortProperty: lazyState.sortField,
        Filter: searchText,
        PropertyNames: ["Name"],
        GoalId: parseInt(id!),
      };
    } else {
      param = {
        Limit: lazyState.rows,
        Page: lazyState.page,
        SortBy: lazyState.sortOrder === 1 ? "ASC" : "DESC",
        SortProperty: lazyState.sortField,
        GoalId: parseInt(id!),
      };
    }
    
    const res: any = userPermissions.includes(goalStep) ?await getAllPaginatedGoalSteps(dispatch, param):[];

    const tempGoal = {
      id: goalInfo.id,
      Name: goalInfo.Name,
      Description: goalInfo.Description,
      Duration: goalInfo.Duration,
      goalSteps: res?.data,
    };
    setTotalGoalSteps(res?.totalCount);

    setGoal(tempGoal);
  
  };

  const checkForErrors = (goal: GoalInfo) => {
    let isValid = true;
    let errors = { ...goalErrors };
    if (!goal.Name.trim()) {
      errors.Name = true;
      isValid = false;
    }
    if (edit || create) {
      if (!goal.Score) {
        errors.Score = true;
        isValid = false;
      }
      if (goal.Score && (goal.Score > 100 || goal.Score < 1)) {
        errors.Score = true;
        isValid = false;
      }
      if ("Duration" in errors) {
        delete errors.Duration;
      }
      if ("Description" in errors) {
        delete errors.Duration;
      }
    } else {
      if (!goal.Duration) {
        errors.Duration = true;
        isValid = false;
      }
      if ("Score" in errors) {
        delete errors.Score;
      }
    }

    setGoalErrors(errors);
    return isValid;
  };
  const checkIsCreateOrEditClicked = async () => {
    if (id) {
      const ifExist = await checkIfGoalExist(parseInt(id));
      if (ifExist.data.Result === null) {
        setIsCreateClicked(true);
      } else {
        setIsCreateClicked(false);
      }
    }
  };
  const transformSkills = async (skills: GoalSkillDto[]) => {
    const allSkillInfo = await getAllSkills(dispatch);
    const transformedallSkills = allSkillInfo.map((skill: SkillDto) => {
      const { id, Name, ...rest } = skill;
      return {
        id,
        Name,
      };
    });
    setAllSkills(transformedallSkills);
    const result = skills
      ?.map((skill) => {
        const { SkillName, GoalId, id, ...rest } = skill; // Extract skillname and retain other properties
        const skillInfo = transformedallSkills?.find(
          (Skill: SkillDto) => Skill.Name === SkillName
        ) as SkillDto;
        // If skillInfo is not found, return undefined (which will be filtered out)
        if (!skillInfo) {
          return undefined;
        }
        return {
          id: skillInfo?.id,
          Name: SkillName, // Rename skillname to Name
        };
      })
      .filter((skill) => skill !== undefined);
    return result;
  };
  const getGoalAndSteps = async () => {
    if (id) {
      const goal: any = await getGoalById(id);
      if (goal === "Goal With this id doesn't exist") {
        return showToast("error", "Goal With this id doesn't exist", "Error");
      }
      const goalInfo = goal.data.Result;
      if (goal) {
        setOnlyGoalInfo(goalInfo);
        setGoalWithSkills({
          GoalName: goalInfo.Name,
          GoalDescription: goalInfo.Description,
          Duration: goalInfo.Duration,
        } as any);
        const transformedSkills: any[] = await transformSkills(goalInfo.Skills); // Transform the skills
        setSelectedSkills([...transformedSkills]);
      }
    }
  };
  useEffect(() => {
    // Clear state or set it to a loading state before fetching new data
    setGoalWithSkills({
      GoalName: "",
      GoalDescription: "",
      Duration: 0,
      Skills: [],
    });
    setSelectedSkills([]);

    getGoalAndSteps();
  }, [id]);

  const handleGoalSave = async () => {
    const errors = { ...goalWithSkillsError };
    if (!goalWithSkills?.GoalName.trim()) {
      errors.GoalName = true;
    }
    if (!goalWithSkills?.Duration) {
      errors.Duration = true;
    }
    if (selectedSkills.length < 1) {
      errors.Skills = true;
    }
    setGoalWithSkillError(errors);
    const hasAnyError = Object.values(errors).some((value) => value === true);
    if (hasAnyError) {
      return showToast("error", "Please fill all required fields", "Error");
    }
    if (id) {
      if (isCreateClicked) {
        const ifGoalExist = await getGoalByGoalName(dispatch, {
          GoalName: goalWithSkills.GoalName.trim(),
        });
        if (ifGoalExist) {
          return showToast(
            "error",
            "A goal with this name already exist",
            "Error"
          );
        } else {
          const input = {
            Name: goalWithSkills.GoalName,
            Description: goalWithSkills.GoalDescription,
            Duration: goalWithSkills.Duration,
            Skills: selectedSkills,
          };

          const res = await createGoalWithSteps(dispatch, input);

          const goalDetails = await getGoalByGoalName(dispatch, {
            GoalName: goalWithSkills.GoalName.trim(),
          });
          const goalId = goalDetails.id;
          setOnlyGoalInfo({
            id: parseInt(id),
            Name: goalWithSkills.GoalName,
            Description: goalWithSkills.GoalDescription,
            Duration: goalWithSkills.Duration,
          });
          // Navigate to the updateGoal page with the specific ID
          navigate(`/updateGoal/${goalId}`);
          showToast("success", "Goal created successfully", "Success");
          toast?.current?.show({
            severity: "warn",
            summary: "Warning",
            detail:
              "If you want to assign this goal to an employee you need to create atleast two goal steps",
            life: 6000,
          });
          setIsCreateClicked(false);
        }
      } else {
        if (goal && goalWithSkills) {
          const successMsg = await updateGoalHeader(dispatch, parseInt(id!), {
            Name: goalWithSkills.GoalName,
            Description: goalWithSkills.GoalDescription,
            Duration: goalWithSkills.Duration,
            Skills: selectedSkills,
            PrevGoalName: goal?.Name,
          });
          if (successMsg === "Goal name already exists") {
            return showToast("error", successMsg, "Error");
          }
          showToast("success", "Goal updated successfully", "Success");
          setOnlyGoalInfo({
            id: parseInt(id),
            Name: goalWithSkills.GoalName,
            Description: goalWithSkills.GoalDescription,
            Duration: goalWithSkills.Duration,
          });
        }
      }
    }
  };
  const handleSave = async () => {
    try {
      if (checkForErrors(goalInfo)) {
        if (edit) {
          if (stepId) {
            const isAlreadyGoalStepPresent =
              await getGoalStepByStepNameAndGoalName(dispatch, {
                Name: goalInfo.Name.trim(),
                GoalName: goalInfo.GoalName,
                StepId: stepId,
              });
            if (isAlreadyGoalStepPresent.length > 0) {
              return showToast("error", "Goal step already present", "Error");
            }
            const successMsg = await updateGoalStep(dispatch, stepId, {
              ...goalInfo,
              Name: goalInfo.Name.trim(),
              Description: goalInfo.Description.trim(),
              PrevStepName: prevStepName,
            });
            showToast("success", successMsg, "Success");
            setUpdateGoalDialog(false);
            await allDataFunc(onlyGoalInfo);
            setGoalInfo(emptyGoal);
            setEdit(false);
          }
        } else if (create) {
          const goalStepLength = goal?.goalSteps?.length;
          const OrderNumber = goalStepLength
            ? goal.goalSteps[goalStepLength - 1].OrderNumber + 1
            : 0;

          const input = [
            {
              ...goalInfo,
              Name: goalInfo.Name.trim(),
              Description: goalInfo.Description.trim(),
              OrderNumber,
              GoalName: goal?.Name,
            },
          ];
          const goalId = parseInt(id!);
          const successMsg = await createGoalSteps(dispatch, input, goalId);

          showToast("success", "Goal Step created successfully", "Success");

          setUpdateGoalDialog(false);
          await allDataFunc(onlyGoalInfo);

          setCreate(false);
        } else {
          if (goal && goalWithSkills) {
            const successMsg = await updateGoalHeader(dispatch, parseInt(id!), {
              Name: goalWithSkills.GoalName,
              Description: goalWithSkills.GoalDescription,
              Duration: goalWithSkills.Duration,
              Skills: selectedSkills,
              PrevGoalName: goal?.Name,
            });

            showToast("success", successMsg, "Success");
          }
          setUpdateGoalDialog(false);

          await allDataFunc(onlyGoalInfo);
          await getGoalAndSteps();
          setGoalInfo(emptyGoal);
          setEdit(false);
        }
      } else {
        showToast(
          "error",
          `Please fill required fields to ${
            create ? "create" : "edit"
          } goal step`,
          "Success"
        );
        return;
      }
    } catch (err: any) {
      showToast("error", err.response.data.Error, "Error");
    }
  };

  const openCreateDialog = () => {
    const hasPermission = isPermissionGiven(createGoalStep, userPermissions, dispatch); 
    if(!hasPermission){
      return 
    }

    setUpdateGoalDialog(true);
    setCreate(true);
    setGoalInfo({
      Name: "",
      Score: 0,
      Description: "",
    });
  };

  const onPageChange = (event: any) => {
    setlazyState(event);
  };

  const onSort = (event: any) => {
    setlazyState((prev: LazyState) => {
      const newState: LazyState = {
        ...prev, // Copy all properties from the previous state
        sortOrder: event.sortOrder, // Update sortOrder
        sortField: event.sortField, // Update sortField
      };
      return newState;
    });
  };

  const onGoalChange = (name: string, value: string | number) => {
    setGoalErrors({ ...goalErrors, [name]: false });

    setGoalInfo((prevGoal) => ({
      ...prevGoal,
      [name]: value,
    }));
  };

  const accept = async (id: number, isGoal: boolean) => {
    if (isGoal) {
      await deleteGoal(dispatch, id);
      await allDataFunc(onlyGoalInfo);
      showToast(
        "success",
        `${
          isGoal
            ? "Goal  deleted successfully"
            : "Goal step deleted successfully"
        }`,
        "Success"
      );
      navigate("/goals");
      return;
    } else {
      if (totalGoalSteps > 2) {
        const res = await deleteGoalStep(dispatch, id as unknown as number);
      } else {
        return showToast(
          "error",
          "More than two goal steps needed to delete any step",
          "Error"
        );
      }
    }

    await allDataFunc(onlyGoalInfo);
    showToast(
      "success",
      `${
        isGoal ? "Goal  deleted successfully" : "Goal step deleted successfully"
      }`,
      "Success"
    );
  };

  const reject = () => {
    showToast("warn", "You have rejected the delete operation", "Rejected");
  };

  const handleKpiDialog = () => {
    setUpdateGoalDialog(false);
    setEdit(false);
    setCreate(false);
    setGoalErrors(emptyGoalErrors);
  };

  const isExpandOrNot = () => {
    if (goal && goal.goalSteps) {
      if (goal.goalSteps.length > 0) {
        const tempDescExpandInfo = { ...isItemToExpand };
        goal.goalSteps.forEach((step) => {
          tempDescExpandInfo[step.id] = false;
        });
        setIsItemToExpand(tempDescExpandInfo);
      }
    }
  };

  const getSkills = async () => {
    const skills = await getAllSkills(dispatch);
    const transformedSkills = skills.map((skill: SkillDto) => {
      const { id, Name, ...rest } = skill;
      return {
        id,
        Name,
      };
    });
    setAllSkills(transformedSkills);
  };

  const handleEditGoalChange = (name: string, value: string | number) => {
    setGoalErrors({ ...goalErrors, [name]: false });
    setGoalWithSkillError({ ...goalWithSkillsError, [name]: false });
    setGoalWithSkills((prevGoal) => ({
      ...prevGoal,
      [name]: value,
    }));
  };

  //#endregion

  //#region all Templates
  const confirm2 = (id: number, isGoal: boolean) => {
    confirmDialog({
      message: (
        <div>
          {isGoal && (
            <p>
              If you delete this goal,all the goals related information will be
              deleted.
            </p>
          )}
          <p>Do you really want to delete this {isGoal ? "goal" : "step"}?</p>
        </div>
      ),
      header: `${localization?.DeleteConfirmation || "Delete Confirmation"}`,
      className: "del_modal",
      headerStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      style: {
        width: "350px",
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      contentStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      acceptClassName: "p-button-primary",
      accept: () => accept(id, isGoal),
      reject,
    });
  };

  const deleteStep = (rowData: any) => {
    const hasPermission = isPermissionGiven(deleteGoalStepInfo, userPermissions, dispatch); 
    if(!hasPermission){
      return 
    }
    confirm2(rowData.id, false);
  };

  const startToolbar = () => {
    return (
      <div className="goal-step-start-toolbar">
        <h4>{localization?.GoalName || goal?.Name}</h4>
      </div>
    );
  };

  const endToolbar = () => {
    return (
      <div className="endToolbar">
        {userPermissions.includes(createGoalStep)&&<Button
          className="justify-content-center"
          label={localization?.CreateStep || "Create Step"}
          onClick={openCreateDialog}
          style={{
            backgroundColor: ThemeColors?.primaryColor,
            border: "none",
          }}
        />}
        <div className="searchBxCont">
            <div className="searchBx border_primary">
                <InputText
                  className="w-100"
                  type="search"
                  placeholder={` ${localization?.Search || "Search"}...`}
                  onChange={(e) => {
                    setTimeout(() => {
                      setSearchText(e.target.value);
                    }, 500);
                  }}
                />
                <span className="searchIcon"><i className="pi pi-search"></i></span>
            </div>
            
        </div>
        
      </div>
    );
  };

  const stepNameTemplate = (rowData: any) => {
    return (
      <>
        {rowData.Name.length > 50 ? (
          <>
            {rowData.Name.slice(0, 50)}
            <span
              className={`stepname-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.stepname-expanded-dots${rowData.id}`}
                content={rowData.Name}
                // style={{ width: "50rem" }}
                className="w-75 w-md-200px"
              />
            </span>
          </>
        ) : (
          rowData.Name
        )}
      </>
    );
  };

  const descriptionTemplate = (rowData: any) => {
    return (
      <>
        {rowData.Description.length > 150 ? (
          <>
            {rowData.Description.slice(0, 150)}
            <span
              className={`description-expanded-dots${rowData.id} expanded-dots`}
              data-pr-position="bottom"
            >
              ...
              <Tooltip
                target={`.description-expanded-dots${rowData.id}`}
                content={rowData.Description}
                style={{ width: "50rem" }}
              />
            </span>
          </>
        ) : (
          rowData.Description
        )}
      </>
    );
  };

  const editDeleteButtonTemplate = (rowData: any) => {
    return (
      <div className="editDeleteButtonTemplate">
        {userPermissions.includes(editGoalStepInfo)&&<Button
          // label={localization?.Edit || "Edit"}
          icon="pi pi-pencil"
          className="editDelButton editBtn"
          style={{ backgroundColor: ThemeColors?.primaryColor }}
          onClick={() => editGoalStep(rowData)}
        />}
        {userPermissions.includes(deleteGoalStepInfo)&&<Button
          icon="pi pi-trash"
          // label={localization?.Delete || "Delete"}
          className="editDelButton delBtn"
          onClick={() => {
            deleteStep(rowData);
          }}
          style={{ backgroundColor: "#d80300" }}
        />}
      </div>
    );
  };

  const dialogHeader = () => {
    return (
      <>
        <div className="dialogHeader">
          {create ? (
            <h4>{localization?.CreateGoalStep || "Create Goal Step"}</h4>
          ) : (
            <h4>
              {edit
                ? localization?.EditGoalStep || "Edit Goal Step"
                : "Edit Goal"}
            </h4>
          )}
        </div>
      </>
    );
  };

  const dialogFooter = () => {
    return (
      <div
        style={{
          padding: "1rem 0",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      >
        <Button
          label={localization?.Save || "Save"}
          className="kpi-save-button"
          onClick={handleSave}
          style={{ backgroundColor: ThemeColors?.primaryColor }}
        />
      </div>
    );
  };

  //#endregion
  useEffect(() => {
    if (selectedSkills.length > 0) {
      setGoalWithSkillError({ ...goalWithSkillsError, Skills: false });
    }
  }, [selectedSkills]);

  useEffect(() => {
    isExpandOrNot();
  }, [goal]);

  useEffect(() => {
    if (!isInitialRender.current) {
      allDataFunc(onlyGoalInfo);
    }
    isInitialRender.current = false;
  }, [onlyGoalInfo]);

  useEffect(() => {
    if (onlyGoalInfo) {
      allDataFunc(onlyGoalInfo);
    }
  }, [lazyState, searchText]);

  useEffect(() => {
    getGoalAndSteps();
    getSkills();
    checkIsCreateOrEditClicked();
  }, []);

  return (
    <div className="updateGoalCont me-2">
      <Toast ref={toast} />
      <ConfirmDialog />
      {onlyGoalInfo?.Name && (
        <Toolbar
          className="bg-transparent border-0 p-0 mb-3"
          start={startToolbar}
          end={endToolbar}
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        />
      )}

      <h4 className="">Create Goal</h4>
      <Card
        className="lightBg_111 border_primary"
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          marginBottom: "20px",
        }}
      >
        
        <div className="grid justify-content-start">
          
          <div className="col-12 col-md-5 col-lg-3">
            <img className="w-100 w-lg-75" src={growingUser} alt="" />
          </div>
          <div className="col-12 col-md-7 col-lg-9">
            
            <div className="edit-goal-container">
              <div className="col-12 w-100 pb-0" style={{ color: mode?.color }}>
                {localization?.GoalName || "Goal Name"}* :
              </div>
              <div className="col-12" style={{}}>
                <InputText
                  style={{ width: "100%" }}
                  value={goalWithSkills?.GoalName}
                  onChange={(e) => handleEditGoalChange("GoalName", e.target.value)}
                />
                {goalWithSkillsError?.GoalName && (
                  <span className="err-msg">*Please enter goal name</span>
                )}
              </div>
            </div>

            <div className="grid m-0">
              <div className="edit-goal-container col-12 col-xl-6">
                <div className="pb-2" style={{ color: mode?.color }}>
                  {" "}
                  {localization?.Duration || "Duration"} (
                  {localization?.Days || "Days"})* :
                </div>
                <div style={{ }}>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={goalWithSkills.Duration}
                    onChange={(e) => handleEditGoalChange("Duration", e.value!)}
                  />
                  {goalWithSkillsError?.Duration && (
                    <span className="err-msg">*Please enter duration</span>
                  )}
                </div>
              </div>
              <div className="edit-goal-container col-12 col-xl-6">
                <div className="pb-2" style={{ color: mode?.color }}>
                  {localization?.Skills || "Skills"}* :
                </div>
                <div style={{ }}>
                  <ModifiedMultiselect
                    selectedSkills={selectedSkills}
                    setSelectedSkills={setSelectedSkills}
                    allSkills={allSkills}
                    setAllSkills={setAllSkills}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    goalWithSkillsError={goalWithSkillsError}
                    setGoalWithSkillError={setGoalWithSkillError}
                  />

                  {goalWithSkillsError?.Skills && (
                    <span className="err-msg">*Please enter skills</span>
                  )}
                </div>
              </div>
            </div>
            

            <div className="edit-goal-container">
              <div className="col-12 pb-0" style={{color: mode?.color }}>
                {localization?.Description || "Description"} :
              </div>
              <div className="col-12 md:col-6" style={{  }}>
                <InputTextarea
                  style={{ width: "100%" }}
                  value={goalWithSkills?.GoalDescription}
                  className="custom-textarea"
                  onChange={(e) =>
                    handleEditGoalChange("GoalDescription", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div
        className="d-flex align-items-center justify-content-end mb-4"
        >
          <Button
            label={localization?.Save || "Save"}
            onClick={handleGoalSave}
          />
        </div>
      </Card>
      {onlyGoalInfo?.Name &&userPermissions.includes(goalStep)&& (
        <div
          // style={{ backgroundColor: mode?.backgroundSecondaryColor }}
          className="kpi_tabile_res updateGoalTable border_primary rounded-3"
        >
          <DataTable
            className="audit-log-table updateGoaltable"
            ref={dt}
            value={goal?.goalSteps}
            selection={selectedKpi}
            onSelectionChange={(e: any) => setSelectedKpi(e.value)}
            dataKey="id"
            lazy
            paginator
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={totalGoalSteps}
            onSort={onSort}
            onPage={onPageChange}
            sortOrder={lazyState.sortOrder}
            sortField={lazyState.sortField}
            showGridlines
            rowsPerPageOptions={row.rowList}
            currentPageReportTemplate="Total Records {totalRecords} "
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          >
            <Column
              className="kpiColumn"
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              field="Name"
              body={stepNameTemplate}
              header={localization?.GoalSteps || "Goal Steps"}
              sortable
            ></Column>
            <Column
              className="kpiColumn"
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              field="Description"
              body={descriptionTemplate}
              header={localization?.Description || "Description"}
            ></Column>
            <Column
              className="kpiColumn col_center col_150"
              bodyStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
                textAlign: "center",
              }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              field="Score"
              header={localization?.Score || "Score"}
              sortable
            ></Column>
            {(userPermissions.includes(editGoalStepInfo) || userPermissions.includes(deleteGoalStepInfo))&&<Column
              className="kpiColumn col_center actionColumn"
              bodyStyle={{ backgroundColor: mode?.backgroundSecondaryColor }}
              headerStyle={{
                backgroundColor: ThemeColors?.primaryColor,
                color: "white",
              }}
              body={editDeleteButtonTemplate}
              header={localization?.Action || "Action"}
            ></Column>}
          </DataTable>
        </div>
      )}

      <Dialog
        className="dialog updateGoalDialog"
        visible={updateGoalDialog}
        header={dialogHeader}
        footer={dialogFooter}
        style={{
          width: "30vw",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        baseZIndex={999}
        onHide={handleKpiDialog}
        draggable={false}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            {localization?.StepName || "Step Name"}*
          </label>
          <InputText
            id="goal-name"
            name="Name"
            value={goalInfo.Name}
            onChange={(e) => onGoalChange(e.target.name, e.target.value)}
          />

          {goalErrors.Name && (
            <small className="p-error">Step Name is required.</small>
          )}
        </div>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            {localization?.Score || "Score"}*
          </label>
          {isEditOrCreate ? (
            <>
              <InputNumber
                inputId="integeronly"
                value={goalInfo.Score}
                name="Score"
                onChange={(e) => {
                  onGoalChange("Score", e.value!);
                }}
                min={0}
                onInput={(e: any) => {
                  onGoalChange("Score", parseInt(e.target.value));
                }}
              />
              {goalErrors.Score &&
                (goalInfo.Score === 0 ||
                  goalInfo.Score === undefined ||
                  goalInfo.Score === null) && (
                  <small className="p-error">Score is required.</small>
                )}
              {goalErrors.Score &&
                goalInfo.Score !== undefined &&
                goalInfo.Score !== null &&
                goalInfo.Score !== 0 &&
                (goalInfo.Score > 100 || goalInfo.Score < 1) && (
                  <small className="p-error">Score not be more than 100.</small>
                )}
            </>
          ) : (
            <>
              <InputNumber
                inputId="integeronly"
                value={goalInfo.Duration}
                name="Duration"
                onChange={(e) => {
                  onGoalChange("Duration", e.value!);
                }}
              />
              {goalErrors.Duration && (
                <small className="p-error">Duration is required.</small>
              )}
            </>
          )}
        </div>
        <div className="field">
          <label htmlFor="information" className="font-bold">
            {localization?.StepDescription || "Step Description"}
          </label>
          <InputTextarea
            className="custom-textarea"
            id="goal-description"
            name="Description"
            value={goalInfo.Description}
            onChange={(e) => onGoalChange(e.target.name, e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateGoalTable;
