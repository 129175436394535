import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import "../../css/components/assignGoal.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Themes } from "../../data/Themes";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import {
  getSubOrdinatesByStatus,
  getNotAssignedGoalsByEmp,
} from "../../service/goals/goalsService";
import { SubOrdinateDto } from "../../dtos/goals/SubOrdinatesDto";
import { CreateGoalsWithStepsDto } from "../../dtos/goals/CreateGoalsWithStepsDto";
import { active } from "../../shared/constant/Common";
import { createUserGoals } from "../../service/userGoals/userGoalService";
import { Toast } from "primereact/toast";

import { NotAssignedGoalDto } from "../../dtos/goals/NotAssignedGoalDto";

interface AssignGoalsProps {
  showAssignGoal: boolean;
  setShowAssignGoal: React.Dispatch<React.SetStateAction<boolean>>;
  getGoals: () => Promise<void>;
}

const AssignGoals: React.FC<AssignGoalsProps> = ({
  showAssignGoal,
  setShowAssignGoal,
  getGoals,
}) => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [subOrdinates, setSubOrdinates] = useState<any>();
  const [selectedSubordinate, setSelectedSubordinate] =
    useState<SubOrdinateDto | null>(null);
  const [selectedGoal, setSelectedGoal] = useState<NotAssignedGoalDto | null>(
    null
  );
  const [notAssignedGoals, setNotAssignedGoals] = useState<
    CreateGoalsWithStepsDto[] | []
  >();
  const [errors, setErrors] = useState<{
    employeeName: boolean;
    goal: boolean;
  }>({ employeeName: false, goal: false });
  const toast = useRef<Toast>(null);

  //#endregion

  //#region all Functions

  const getAllEmployees = async () => {
    const response = await getSubOrdinatesByStatus(dispatch, {
      Status: active,
    });
    setSubOrdinates(response.subordinates);
  };

  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const getNotAssignedGoals = async () => {
    if (selectedSubordinate) {
      const response = await getNotAssignedGoalsByEmp(dispatch, {
        empCode: selectedSubordinate.employeeCode,
      });
      setNotAssignedGoals(response);
    }
  };

  const submitUserGoal = async () => {
    if (!selectedSubordinate && !selectedGoal) {
      setErrors({ employeeName: true, goal: true });
      showToast("error", "Please fill required fields to assign goal", "Error");
    } else if (!selectedSubordinate) {
      setErrors({ employeeName: true, goal: false });
      showToast("error", "Please fill required fields to assign goal", "Error");
    } else if (!selectedGoal) {
      setErrors({ employeeName: false, goal: true });
      showToast("error", "Please fill required fields to assign goal", "Error");
    } else {
      await createUserGoals(dispatch, {
        EmployeeCode: selectedSubordinate?.employeeCode,
        GoalId: selectedGoal?.id,
        GoalName: selectedGoal?.Name,
        IsGoalCompleted: false,
      });
      showToast("success", "Assigned Goal Successfully", "Success");
      getGoals();
      setShowAssignGoal(false);
      resetAssignGoalDialog();
    }
  };

  const resetAssignGoalDialog = () => {
    setSelectedGoal(null);
    setSelectedSubordinate(null);
    setErrors({ employeeName: false, goal: false });
  };

  //#endregion

  //#region all Templates
  const selectedEmployeeTemplate = (option: SubOrdinateDto, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.fullName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const selectedGoalTemplate = (option: NotAssignedGoalDto, props: any) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.Name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const employeeOptionTemplate = (option: SubOrdinateDto) => {
    return (
      <div className="flex align-items-center">
        <div>{option.fullName}</div>
      </div>
    );
  };
  const goalOptionTemplate = (option: NotAssignedGoalDto) => {
    return (
      <div className="flex align-items-center">
        <div>{option.Name}</div>
      </div>
    );
  };

  const assignGoalsHeaderTemplate = () => {
    return <h1 className="m-0 p-dialog-title">{localization?.AssignGoal || "Assign Goals"}</h1>;
  };

  const addGoalFooterTemplate = () => {
    return (
      <div className="form-actions">
        <Button
          className="modalsecondaryBtn"
          type="button"
          style={{
            backgroundColor: mode?.backgroundSecondaryColor,
            border: `1px solid ${modeName === "light" ? "#0355a0" : "white"}`,
            color: `${modeName === "light" ? "#0355a0" : "white"}`,
          }}
          onClick={() => {
            setShowAssignGoal(false);
            resetAssignGoalDialog();
          }}
        >
          {localization?.Cancel || "Cancel"}
        </Button>
        <Button type="submit" onClick={submitUserGoal}
        style={{
              backgroundColor: ThemeColors?.primaryColor,
             color: `${modeName === "light" ? "#FFF" : "white"}`,
           }}>
          {localization?.Submit || "Submit"}
        </Button>
      </div>
    );
  };

  //#endregion

  useEffect(() => {
    getNotAssignedGoals();
  }, [selectedSubordinate]);

  useEffect(() => {
    getAllEmployees();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="assign_Goals"
        header={assignGoalsHeaderTemplate}
        footer={addGoalFooterTemplate}
        visible={showAssignGoal}
        style={{
          width: "30vw",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        onHide={() => {
          if (!showAssignGoal) return;
          setShowAssignGoal(false);
          resetAssignGoalDialog();
        }}
        draggable={false}
      >
        <div className="assign-goal-form">
          <div className="form-group mb-3">
            <label htmlFor="goal-name">{localization?.EmployeeName || "Employee Name"}</label>
            <div
             className="dropdown-styles"
            >
              <Dropdown
                className="w-100"
                value={selectedSubordinate}
                onChange={(e) => {
                  setSelectedSubordinate(e.value);
                  setErrors((prev) => {
                    return { ...prev, employeeName: false };
                  });
                }}
                options={subOrdinates}
                optionLabel="fullName"
                placeholder={localization?.SelectAnEmployee || "Select an Employee"}
                valueTemplate={selectedEmployeeTemplate}
                itemTemplate={employeeOptionTemplate}
                filter
                panelStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              />
              {errors.employeeName && (
                <span className="err-msg">*Please enter employee name</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="duration">{localization?.AddGoal || "Add Goal"}</label>
            <div
              className="dropdown-styles"
            >
              <Dropdown
                value={selectedGoal}
                onChange={(e) => {
                  setSelectedGoal(e.value);
                  setErrors((prev) => {
                    return { ...prev, goal: false };
                  });
                }}
                options={notAssignedGoals}
                optionLabel="Name"
                placeholder={localization?.SelectAGoal || "Select a Goal"}
                valueTemplate={selectedGoalTemplate}
                itemTemplate={goalOptionTemplate}
                filter
                disabled={!selectedSubordinate}
                panelClassName="custom-dropdown"
                panelStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              />

              {errors.goal && (
                <span className="err-msg">*Please enter goal</span>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AssignGoals;
