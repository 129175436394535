export function getBulbSvg(fillColor:string) {
    return <svg width="18" height="18" viewBox="0 0 26 26" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1124_1717)">
    <path d="M16.25 21.125H9.75C9.30093 21.125 8.9375 21.4884 8.9375 21.9375V23.5625C8.9375 24.9066 10.0309 26 11.375 26H14.625C15.9691 26 17.0625 24.9066 17.0625 23.5625V21.9375C17.0625 21.4884 16.6991 21.125 16.25 21.125Z" fill={fillColor} fill-opacity="0.94"/>
    <path d="M12.9998 12.5269C14.5335 12.5269 15.7769 11.2835 15.7769 9.74978C15.7769 8.21602 14.5335 6.97266 12.9998 6.97266C11.466 6.97266 10.2227 8.21602 10.2227 9.74978C10.2227 11.2835 11.466 12.5269 12.9998 12.5269Z" fill={fillColor} fill-opacity="0.94"/>
    <path d="M22.6606 8.40133C22.0837 4.07071 18.5006 0.568832 14.1538 0.0650819C11.3669 -0.259918 8.58 0.625707 6.50813 2.47008C4.43625 4.32258 3.25 6.97133 3.25 9.75008C3.25 13.4063 5.265 16.7213 8.50688 18.4032C8.76688 18.5413 8.9375 18.8176 8.9375 19.1182V19.6463C9.18937 19.5488 9.46562 19.5001 9.75 19.5001H16.25C16.5344 19.5001 16.8106 19.5488 17.0625 19.6463V19.1182C17.0625 18.8176 17.225 18.5413 17.4931 18.4032C21.19 16.4857 23.2131 12.5613 22.6606 8.40133ZM17.875 10.5626H17.3237C17.2092 11.1727 16.9704 11.739 16.6317 12.2329L17.0212 12.6224C17.3386 12.9398 17.3386 13.4539 17.0212 13.7714C16.8625 13.9301 16.6546 14.0095 16.4468 14.0095C16.239 14.0095 16.031 13.9301 15.8723 13.7714L15.4828 13.3819C14.9889 13.7206 14.4226 13.9594 13.8125 14.0739V14.6252C13.8125 15.0742 13.4491 15.4377 13 15.4377C12.5509 15.4377 12.1875 15.0742 12.1875 14.6252V14.0739C11.5774 13.9594 11.0111 13.7206 10.5172 13.3819L10.1276 13.7714C9.96897 13.9301 9.76105 14.0095 9.55321 14.0095C9.34538 14.0095 9.13746 13.9301 8.97878 13.7714C8.66141 13.454 8.66141 12.9399 8.97878 12.6224L9.36829 12.2329C9.02956 11.739 8.79076 11.1727 8.67628 10.5626H8.125C7.67593 10.5626 7.3125 10.1992 7.3125 9.75008C7.3125 9.30101 7.67593 8.93758 8.125 8.93758H8.67628C8.79076 8.32748 9.02956 7.76116 9.36829 7.26724L8.97878 6.87773C8.66141 6.56037 8.66141 6.04622 8.97878 5.72878C9.29614 5.41133 9.81029 5.41141 10.1277 5.72878L10.5172 6.11829C11.0112 5.77956 11.5775 5.54076 12.1876 5.42628V4.875C12.1876 4.42593 12.551 4.0625 13.0001 4.0625C13.4491 4.0625 13.8126 4.42593 13.8126 4.875V5.42628C14.4227 5.54076 14.989 5.77956 15.4829 6.11829L15.8724 5.72878C16.1898 5.41141 16.7039 5.41141 17.0214 5.72878C17.3388 6.04614 17.3387 6.56029 17.0214 6.87773L16.6319 7.26724C16.9706 7.76116 17.2094 8.32748 17.3239 8.93758H17.8752C18.3242 8.93758 18.6877 9.30101 18.6877 9.75008C18.6877 10.1992 18.3241 10.5626 17.875 10.5626Z" fill={fillColor} fill-opacity="0.94"/>
    <path d="M1.625 10.5625H0.8125C0.363431 10.5625 0 10.1991 0 9.75C0 9.30093 0.363431 8.9375 0.8125 8.9375H1.625C2.07407 8.9375 2.4375 9.30093 2.4375 9.75C2.4375 10.1991 2.07407 10.5625 1.625 10.5625Z" fill={fillColor} fill-opacity="0.94"/>
    <path d="M4.95721 2.51933C4.74929 2.51933 4.54145 2.43995 4.38277 2.28127L3.80833 1.70683C3.49097 1.38947 3.49097 0.87532 3.80833 0.557877C4.1257 0.240433 4.63985 0.240514 4.95729 0.557877L5.53173 1.13231C5.84909 1.44968 5.84909 1.96383 5.53173 2.28127C5.37305 2.43995 5.16505 2.51933 4.95721 2.51933Z" fill={fillColor} fill-opacity="0.94"/>
    <path d="M4.38277 19.1804C4.17485 19.1804 3.96702 19.1011 3.80833 18.9424C3.49097 18.625 3.49097 18.1109 3.80833 17.7934L4.38277 17.219C4.70013 16.9016 5.21428 16.9016 5.53173 17.219C5.84917 17.5363 5.84909 18.0505 5.53173 18.3679L4.95729 18.9424C4.79861 19.1011 4.59061 19.1804 4.38277 19.1804Z" fill={fillColor} fill-opacity="0.94"/>
    <path d="M25.1875 10.5625H24.375C23.9259 10.5625 23.5625 10.1991 23.5625 9.75C23.5625 9.30093 23.9259 8.9375 24.375 8.9375H25.1875C25.6366 8.9375 26 9.30093 26 9.75C26 10.1991 25.6366 10.5625 25.1875 10.5625Z" fill={fillColor} fill-opacity="0.94"/>
    <path d="M21.0429 2.5193C20.835 2.5193 20.6272 2.43992 20.4685 2.28124C20.1511 1.96388 20.1511 1.44973 20.4685 1.13228L21.0429 0.557846C21.3603 0.240484 21.8744 0.240484 22.1919 0.557846C22.5093 0.875209 22.5092 1.38936 22.1919 1.7068L21.6174 2.28124C21.4588 2.43992 21.2508 2.5193 21.0429 2.5193Z" fill={fillColor} fill-opacity="0.94"/>
    <path d="M21.6174 19.18C21.4094 19.18 21.2016 19.1006 21.0429 18.9419L20.4685 18.3675C20.1511 18.0501 20.1511 17.536 20.4685 17.2185C20.7859 16.9011 21.3 16.9012 21.6174 17.2185L22.1919 17.793C22.5092 18.1103 22.5092 18.6245 22.1919 18.9419C22.0332 19.1006 21.8252 19.18 21.6174 19.18Z" fill={fillColor} fill-opacity="0.94"/>
    </g>
    <defs>
    <clipPath id="clip0_1124_1717">
    <rect width="26" height="26" fill={fillColor}/>
    </clipPath>
    </defs>
    </svg>

}