import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { forwardRef, useEffect, useRef, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Card } from "primereact/card";
import { getAllProjectBillingData } from "../../service/projectBilling/ProjectBillingService";
import { ProjectBillingDto } from "../../dtos/projectBilling/ProjectBillingDto";
import { getProjectByUser } from "../../service/project/ProjectService";
import { ProjectBillingTasksDto } from "../../dtos/projectBilling/ProjectBillingTasksDto";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ShowMinToHourAndMin } from "../../shared/functions/ShowMinToHourAndMin";
import { ProjectBillingEmployeesDto } from "../../dtos/projectBilling/ProjectBillingEmployeesDto";
import { InputTimeDifference } from "../subComponents/InputTimeDifference";
import { InputForCreateOrUpdateEmployeeBillingDto } from "../../dtos/employeeBilling/InputForCreateOrUpdateEmployeeBillingDto";
import {
  createBulkBillings,
  createEmployeeBilling,
  createProjectBilling,
} from "../../service/employeeBilling/EmployeeBillingService";
import { Dropdown } from "primereact/dropdown";
import { AllProjectDto } from "../../dtos/project/AllProjectDto";
import { Button } from "primereact/button";
import { ISTToDate } from "../../shared/functions/ISTToDate";
import { MultiSelect } from "primereact/multiselect";
import { getNextDate } from "../../shared/functions/GetNextDate";
import {
  getEmployeeDetailsByAccess,
  getSubordinatesNameIDCode,
} from "../../service/employee/employeeService";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import DatePicker from "react-datepicker";
import { isDateValid } from "../../shared/functions/IsValidDateFunc";
import { GetPrevFirstAndLastDayOfWeek } from "../../shared/functions/GetPrevFirstAndLastDayOfWeek";
import { GetNextFirstAndLastDayOfWeek } from "../../shared/functions/GetNextFirstAndLastDayOfWeek";
import { SelectButton } from "primereact/selectbutton";
import "../../css/components/employeeBilling.css";
import { ProjectBillingDatesDto } from "../../dtos/projectBilling/ProejctBillingDatesDto";
import { Tooltip } from "primereact/tooltip";
import { LoggedInUserData } from "../../shared/functions/LoggedInUserData";
import { OverlayPanel } from "primereact/overlaypanel";
import { EmployeeBillingInputDto } from "../../dtos/employeeBilling/EmployeeBillingInputDto";
import { Toast } from "primereact/toast";
import { addProjectBillingTime, updateProjectBillingTime } from "../../shared/constant/PermissionVariables";
import { isPermissionGiven } from "../../shared/functions/HasPermission";
import { InputNumber } from "primereact/inputnumber";

export const ProjectBilling = () => {
  //#region all Variables

  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const dispatch = useDispatch();
  const [date, setDate] = useState<any>(new Date());
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const [project, setProject] = useState<AllProjectDto[]>([]);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [tasks, setTasks] = useState<ProjectBillingTasksDto[]>();
  const [projectName, setProjectName] = useState<string>("");
  const [projectId, setProjectId] = useState<number>(0);
  const [employee, setEmployee] = useState<any[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<string[] | null>(
    null
  );
  const [projTotalBillTime, setprojTotalBillTime] = useState(0);
  const [calender, setCalender] = useState(
    <DatePicker selected={date} onChange={(e) => setDate(e as Date)} />
  );
  const [noData, setNodata] = useState("");
  const buttons: any[] = [
    { name: localization?.Day || "Day", value: "day" },
    { name: localization?.Week || "Week", value: "week" },
  ];
  const [dateType, setDateType] = useState<string>(buttons[0].value);
  const [disableSelectBtn, setDisableSelectBtn] = useState<boolean>(false);
  const [weekDate, setWeekDate] = useState<any>(new Date());
  const [prevDate, setPrevDate] = useState<any>(new Date());
  const [newlyAddedEmp, setNewlyAddedEmp] = useState<any>();
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [newlyAddedTaskDate, setNewlyAddedTaskDate] = useState<Date>(date);
  const [billingData, setBillingData] = useState<ProjectBillingTasksDto[]>([]);
  const op = useRef<any>(null);
  const toast = useRef<any>(null);
  const userPermissions = useSelector((state: any) => state.permission.permissions);
  //#endregion

  //#region all Functions

  function calculateTotalBillingTime(data: ProjectBillingTasksDto[]) {
    let totalBillingTime = 0;

    data?.forEach((task) => {
      task?.employees?.forEach((emp) => {
        emp.datesOfTask.forEach((date) => {
          totalBillingTime += date.billingTimeInMinute as number;
        });
      });
    });

    return totalBillingTime;
  }

  const afterCreateDataFunc = (
    input: InputForCreateOrUpdateEmployeeBillingDto
  ) => {
    setTasks((prevData) => {
      const newData = [...(prevData as ProjectBillingTasksDto[])];

      for (const task of newData) {
        if (task.taskId === input.TaskID && task.taskName === input.TaskName) {
          for (const emp of task.employees) {
            if (emp.id === input.EmployeeID) {
              if (emp.id === input.EmployeeID) {
                for (const dates of emp.datesOfTask) {
                  if (dates.date === input.TrackingDate) {
                    dates.billingTimeInMinute =
                      input.BillingTimeInMinute as number;
                  }
                }
              }
            }
          }
        }
      }

      return newData;
    });
    const totalBillTime = calculateTotalBillingTime(
      tasks as ProjectBillingTasksDto[]
    );
    setprojTotalBillTime(totalBillTime);
  };

  const createEmpAndProjArr = (arr: any[]) => {
    const data: number[] = [];
    arr?.forEach((e: any) => {
      data.push(e?.id);
    });
    if (data.length === employee.length) {
      data.length = 0;
    }
    return data;
  };

  const getAllData = async () => {
    setDisableSelectBtn(true);
    // getting logged in user's employee code
    const loggedInUser = await LoggedInUserData(dispatch);

    let validDateOrNot: boolean[] = [];
    if (Array.isArray(date)) {
      date.map((val) => {
        const isValidDate = isDateValid(val);
        validDateOrNot.push(isValidDate);
      });
    } else {
      const isValidDate = isDateValid(date);
      validDateOrNot.push(isValidDate);
    }

    if (!validDateOrNot.includes(false)) {
      let startDate = "";
      let endDate = "";
      const today = ISTToDate(date);
      if (Array.isArray(today)) {
        startDate = today[0];
        endDate = today[1];
      } else {
        startDate = today;
        endDate = getNextDate(today as string);
      }

      if (typeof selectedProject === "undefined") {
        setSelectedProject(null);
        setDisableSelectBtn(false);
        setTasks([]);
        return;
      }

      if (selectedProject === null) {
        setDisableSelectBtn(false);
        setTasks([]);
        return;
      }

      const input: EmployeeBillingInputDto = {
        StartTime: startDate,
        EndTime: endDate,
        UserIds: createEmpAndProjArr(selectedEmployee as string[]),
        ProjectDetails: [
          {
            id: selectedProject.id,
            name: selectedProject.name,
          },
        ],
        loggedInUserEmpCode: loggedInUser.EmployeeCode,
      };

      const data: ProjectBillingDto[] = await getAllProjectBillingData(
        dispatch,
        input
      );
      if (data.length > 0) {
        setTasks(data[0]?.tasks);
        setBillingData(data[0]?.tasks);
        setProjectId(selectedProject.id);
        setProjectName(selectedProject.name);
        handleProjBillTime(data[0]?.tasks);
        setNodata("");
      } else {
        setTasks([]);
        setProjectId(0);
        setprojTotalBillTime(0);
        setNodata("Sorry there is no data to show for this match");
      }
    } else {
      setNodata("Please provide a valid date in MM/DD/YYYY to show the data.");
    }
    setDisableSelectBtn(false);
  };

  const handleProjBillTime = (arr: ProjectBillingTasksDto[]) => {
    let total: number = 0;
    let totalEmpBillTime: number = 0;
    arr.forEach((task) => {
      task.employees.forEach((emp) => {
        emp.datesOfTask.forEach((date) => {
          totalEmpBillTime += date.billingTimeInMinute as number;
        });
      });
    });
    total += totalEmpBillTime;

    setprojTotalBillTime(total);
  };
 

  const getAllProjects = async () => {
    const projects = await getProjectByUser(dispatch,null);
    setProject(projects?.result?.projects);
  };

  const getAllEmployees = async () => {
    const employees = await getEmployeeDetailsByAccess(dispatch);
    setEmployee(employees);
  };

  const handleSave = async (
    e: number,
    data: InputForCreateOrUpdateEmployeeBillingDto
  ) => {
    const hasPermission = isPermissionGiven(updateProjectBillingTime, userPermissions, dispatch); 
    if(!hasPermission){
      return 
    }
    const createData: InputForCreateOrUpdateEmployeeBillingDto = {
      EmployeeID: data.EmployeeID,
      EmployeeCode: data.EmployeeCode,
      EmployeeName: data.EmployeeName,
      ProjectID: projectId,
      ProjectName: projectName,
      TaskID: data.TaskID,
      TaskName: data.TaskName,
      TrackTimeInMinute: Math.round(data.TrackTimeInMinute),
      BillingTimeInMinute: e,
      TrackingDate: data.TrackingDate,
    };
    await createProjectBilling(dispatch, createData);
    afterCreateDataFunc(createData);
  };

  const handleTaskBillTime = (arr: ProjectBillingEmployeesDto[]) => {
    let res = 0;
    arr.forEach((emp) => {
      emp.datesOfTask.forEach((date) => {
        res += date.billingTimeInMinute;
      });
    });
    return res;
  };

  const handlePrevDate = (type: string, date: any) => {
    if (type === "day") {
      const previous = new Date(date?.getTime());
      previous.setDate(date?.getDate() - 1);
      setDate(previous);
      setPrevDate(previous);
    } else if (type === "week") {
      const daysOfWk = GetPrevFirstAndLastDayOfWeek(date);
      const dateRange = [];
      dateRange.push(daysOfWk.first, daysOfWk.last);
      setDate(dateRange);
      setWeekDate(dateRange);
    }
  };

  const handleNextDate = (type: string, date: any) => {
    if (type === "day") {
      const next = new Date(date?.getTime());
      next.setDate(date?.getDate() + 1);
      if(next>new Date()){
        toast.current.show({
          severity: "warn",
          summary: "Sorry!",
          detail: "You can't go to the upcoming days!",
          life: 3000,
        });
        return;
      }
      setDate(next);
      setPrevDate(next);
    }
    //  else if (type === "week") {
    //   const daysOfWk = GetNextFirstAndLastDayOfWeek(date);
    //   const dateRange = [];
    //   dateRange.push(daysOfWk.first, daysOfWk.last);
    //   setDate(dateRange);
    //   setWeekDate(dateRange);
    // }
    else if (type === "week") {
      const currentDate = new Date();
    
      // Calculate the start and end of the current week
      const currentWeekStart = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay() // Start of the week (Sunday)
      );
      const currentWeekEnd = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay()) // End of the week (Saturday)
      );
    
      // Get the next week's start and end dates using the function
      const daysOfWk = GetNextFirstAndLastDayOfWeek(date);
    
      // Compare next week with the current week
      if (daysOfWk.first > currentWeekEnd) {
        toast.current.show({
          severity: "warn",
          summary: "Sorry!",
          detail: "You can't go to the upcoming weeks!",
          life: 3000,
        });
        return;
      }
    
      // Proceed with setting the next week if it's valid
      const dateRange = [daysOfWk.first, daysOfWk.last];
      setDate(dateRange);
      setWeekDate(dateRange);
    }
    
  };

  const employeeOptions = employee.map((emp) => ({
    label: `${emp.fullName} (${emp.employeeCode})`,
    value: emp, // You can set the entire employee object as the value if needed
  }));

  const getFirstAndLastDayOfWeek = (date: any) => {
    const today = new Date(date); // Create a clone of the input date

    const currentDate = new Date();
    
    // Calculate the start and end of the current week
    const currentWeekStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay() // Start of the week (Sunday)
    );
    const currentWeekEnd = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDate.getDay()) // End of the week (Saturday)
    );

    // Set the first day of the week (Monday)
    const firstDay = new Date(today);
    firstDay.setDate(
      today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
    );

    // Set the last day of the week (Sunday)
    const lastDay = new Date(firstDay);
    lastDay.setDate(firstDay.getDate() + 6);

    if (firstDay > currentWeekEnd) {
      toast.current.show({
        severity: "warn",
        summary: "Sorry!",
        detail: "You can't go to the upcoming weeks!",
        life: 3000,
      });
      return;
    }

    const weekDates = [firstDay, lastDay];
    setDate(weekDates); // Assuming setDate is a function you want to use to set the week dates
    setWeekDate(weekDates);
  };

  //after clicking the day button dates will set to day
  const handleDay = () => {
    setDate(prevDate);
  };

  //after clicking the week button dates will set to week

  const handleWeek = () => {
    if (weekDate.length > 0) {
      const dt = [weekDate[0], weekDate[1]];
      setDate(dt);
    } else {
      const today = new Date(weekDate);
      const first = new Date(today);
      first.setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      );
      const last = new Date(today);
      last.setDate(first.getDate() + 6);
      const dateRange = [first, last];
      setDate(dateRange);
    }
  };

  const handleDateType = (e: any) => {
    let dateTypeForPicker;
    if (e.value === null) {
      dateTypeForPicker = dateType;
      setDateType((prev: any) => {
        return prev;
      });
    } else {
      setDateType(e.value);
      dateTypeForPicker = e.value;
    }

    if (e.value === "day") {
      handleDay();
    } else if (e.value === "week") {
      // getFirstAndLastDayOfWeek([new Date()]);
      handleWeek();
    }
  };

  const handleCalender = () => {
    if (dateType === "day") {
      setCalender(
        <DatePicker selected={date} onChange={(e) =>{
          if ( e && e >= new Date()) {
            toast.current.show({
              severity: "warn",
              summary: "Sorry!",
              detail: "You can't go to the upcoming weeks!",
              life: 3000,
            });
            return;
          } 
          setDate(e as Date)
        }} />
      );
      setPrevDate(date);
    } else if (dateType === "week") {
      setCalender(
        <DatePicker
          selectsRange={date}
          customInput={<CustomInput />}
          startDate={date[0]}
          endDate={date[1]}
          onChange={(e: any) => getFirstAndLastDayOfWeek(e)}
          dateFormatCalendar="MMMM yyyy"
        />
      );
      setWeekDate(date);
    }
  };

  const calculateTotalDatesBillTime = (arr: ProjectBillingDatesDto[]) => {
    return arr.reduce(
      (total, date) => (total + date?.billingTimeInMinute) as number,
      0
    );
  };

  const openAddTask = (e: any) => {
    const hasPermission = isPermissionGiven(addProjectBillingTime, userPermissions, dispatch); 
    if(!hasPermission){
      return 
    }
    setSelectedTask(e.task);
    op.current.toggle(e.val);
    if (dateType === "day") {
      setNewlyAddedTaskDate(date);
    } else {
      setNewlyAddedTaskDate(new Date());
    }
  };

  //after selecting newly added employee
  const handleNewlyAddedEmp = () => {
    if (!newlyAddedEmp) {
      toast.current.show({
        severity: "warn",
        summary: "Please Note!",
        detail: "Please select an employee to add the task!",
        life: 3000,
      });
      return;
    }

    //is future date then the function will not execute
    if (newlyAddedTaskDate > new Date()) {
      toast.current.show({
        severity: "warn",
        summary: "Please Note!",
        detail: "You can't add any task for future date!",
        life: 3000,
      });
      return;
    }

    //add the user in the main data
    let newTasks = tasks;
    newTasks?.forEach((newTask) => {
      //find the task
      if (newTask.taskId === selectedTask) {
        //is same employee
        const isPresentEmp = newTask.employees.find(
          (emp) => emp.employeeCode === newlyAddedEmp.employeeCode
        );
        //changed Code
        const isEmpAlreadyExist = newTask.employees.find(
          (emp) => emp.employeeCode === newlyAddedEmp.employeeCode
        );
        const isDateAlreadyPresent = isEmpAlreadyExist?.datesOfTask.find(
          (date) => date.date === ISTToDate(newlyAddedTaskDate)
        );
        if (isEmpAlreadyExist && isDateAlreadyPresent) {
          return toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Sorry,same billing date can't be added!",
            life: 3000,
          });
        }
        //till this
        if (isPresentEmp) {
          isPresentEmp.datesOfTask.push({
            billingTimeInMinute: 0,
            totalTrackTimeInMinute: 0,
            date: ISTToDate(newlyAddedTaskDate) as string,
          });
          op.current.hide(); //changed code
          setNewlyAddedEmp(null);
          setSelectedTask(null);
        } else {
          //if not same employee
          newTask.employees.push({
            employeeCode: newlyAddedEmp.employeeCode,
            id: newlyAddedEmp.id,
            fullName: newlyAddedEmp.fullName,
            billingTimeInMinute: 0,
            totalTrackTimeInMinute: 0,
            isLoggedInUser: false,
            datesOfTask: [
              {
                totalTrackTimeInMinute: 0,
                billingTimeInMinute: 0,
                date: ISTToDate(newlyAddedTaskDate) as string,
              },
            ],
          });
          op.current.hide(); //changed code
          setNewlyAddedEmp(null);
          setSelectedTask(null);
        }
      }
    });

    setTasks(newTasks);
  
  };

  const handleAllTrackingToBill = async () => {
    let input: InputForCreateOrUpdateEmployeeBillingDto[] = [];

    billingData.forEach((billData) => {
      billData.employees.forEach((employee) => {
        employee.datesOfTask.forEach((date) => {
          input.push({
            TaskID: billData.taskId,
            TaskName: billData.taskName,
            EmployeeCode: employee.employeeCode,
            EmployeeID: employee.id,
            EmployeeName: employee.fullName,
            ProjectID: projectId,
            ProjectName: projectName,
            TrackingDate: date.date,
            BillingTimeInMinute: date.totalTrackTimeInMinute,
            TrackTimeInMinute: date.totalTrackTimeInMinute,
          });
        });
      });
    });

    await createBulkBillings(dispatch, input);

    await getAllData();

    toast.current.show({
      severity: "success",
      summary: "Success!",
      detail:
        "You have successfully save all the tracking time to billing time!",
      life: 3000,
    });
  };

  //#endregion

  //#region all Templates

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const startToolbar = () => {
    return (
      <div className="w-100 d-block">
        <h4>{localization?.ProjectBilling || "Project Billing"}</h4>
        <p>
          {localization?.ProjectName || "Project Name"}: {selectedProject?.name}
        </p>
        <p>
          {localization?.BillingTime || "Billing Time"}:
          {selectedProject === null
            ? ""
            : ShowMinToHourAndMin("", projTotalBillTime)}
        </p>
      </div>
    );
  };

  const endToolbar = () => {
    return (
      <div className="endToolbarPerformance">
        <Button
          label="Set Billing"
          onClick={handleAllTrackingToBill}
          disabled={!tasks || tasks?.length == 0 || disableSelectBtn}
        />

        <Dropdown
          value={selectedProject}
          disabled={disableSelectBtn}
          filter
          resetFilterOnHide={true}
          onChange={(e) => setSelectedProject(e.value)}
          options={project}
          optionLabel="name"
          placeholder={localization?.SelectProjects || "Select Projects"}
          className="w-full md:w-13rem inner_dropdown"
          style={{ width: "11rem", color: "black" }}
          showClear
          panelStyle={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        />
        <MultiSelect
          value={selectedEmployee}
          filter
          resetFilterOnHide={true}
          disabled={selectedProject === null ? true : false}
          onChange={(e) => setSelectedEmployee(e.value)}
          options={employeeOptions}
          placeholder={localization?.SelectEmployees || "Select Employees"}
          className="w-full md:w-13rem select_width_issue"
          style={{ width: "11rem" }}
          display="chip"
          panelStyle={{
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        />

        <SelectButton
          value={dateType}
          disabled={disableSelectBtn}
          onChange={(e) => handleDateType(e)}
          optionLabel="name"
          options={buttons}
        />

        <div className="date-input border_primary flex-fill rounded-3">
          <Button
            icon={<AiOutlineLeft />}
            disabled={disableSelectBtn}
            onClick={() => handlePrevDate(dateType, date)}
            className="date-comp-prev-btn"
          />
          {calender}
          <Button
            icon={<AiOutlineRight />}
            disabled={disableSelectBtn}
            onClick={() => handleNextDate(dateType, date)}
            className="date-comp-next-btn"
          />
        </div>
      </div>
    );
  };

  //#endregion
  useEffect(() => {
    getAllEmployees();
    getAllProjects();
  }, []);

  useEffect(() => {
    handleCalender();
  }, [dateType, date]);

  useEffect(() => {
    getAllData();
    if (selectedProject === null) {
      setNodata("Please select a project for data");
    }
  }, [date, selectedEmployee, selectedProject]);

  return (
    <div className="project_billing">
      <Toast ref={toast} />
      <Toolbar
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="flex-column1 bg-transparent border-0 p-0 mb-3"
      />
      <div
        // style={{
        //   backgroundColor: mode?.backgroundSecondaryColor,
        //   color: mode?.color,
        // }}
      >
        {noData.length > 0 ? (
          <div className="no-data">{noData}</div>
        ) : (
          <div className="main_project_billing">
            {tasks?.map((task: ProjectBillingTasksDto, index: number) => {
              const totalTaskBillTime = handleTaskBillTime(task.employees);
              return (
                <div style={{position:'relative'}}>
                       <div className="addBtn projectAdd d-flex justify-content-end" >
                          {userPermissions.includes(addProjectBillingTime)&&<Button
                            label="Add"
                            icon="pi pi-plus"
                            outlined
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event propagation to the accordion header
                              openAddTask({ val: e, task: task.taskId });
                            }}
                            style={{background:'#1E7FD9'}}
                          />}
                        </div>
                <Accordion
                  activeIndex={0}
                  key={task.taskId}
                  className="rounded-3 overflow-hidden border_primary custom-accordion"
                
                >
                  <AccordionTab
                    key={task.taskId}
                    headerClassName="employee-billing-head employee-top-head"
                    headerStyle={{
                      backgroundColor: ThemeColors?.primaryColor,
                      color: ThemeColors?.fontColor,
                    }}
                    header={
                      <table
                        className="employee-billing-employee-part input_billing"
                        key={task.taskId}
                      >
                      

                        
                        <tr style={{ width: "100%",display:'flex',justifyContent:'space-between' }}>

                        
                        <Tooltip
                          target=".task-name"
                          mouseTrack
                          mouseTrackLeft={10}
                        />
                        <th
                          className="task-name"
                          data-pr-tooltip={task.taskName}
                          style={{width: '30%'}}
                        >
                          {task?.taskName?.length > 30
                            ? `${task?.taskName?.slice(0, 31)}...`
                            : task.taskName}
                            
                        </th>

                        <th className="totalTrackTimeHead" style={{width: '30%'}}>
                          <span>Total Track Time:{" "}</span>
                          <span>{ShowMinToHourAndMin("", task.totalTrackTimeInMinute)}</span>
                        </th>
                        <th style={{width: '30%'}}>
                          {ShowMinToHourAndMin(
                            "Billing Time: ",
                            totalTaskBillTime
                          )}
                        </th>
                   
                        </tr>
                       
                      </table>
                    }
                  >
                    {task?.employees?.map((emp: ProjectBillingEmployeesDto) => {
                      const billingTime = calculateTotalDatesBillTime(
                        emp.datesOfTask
                      );

                      return (
                       
                        <Accordion
                          activeIndex={Array.from(
                            Array(emp?.datesOfTask?.length).keys()
                          )}
                          multiple
                          key={emp.employeeCode}
                        >
                          <AccordionTab
                            key={emp.employeeCode}
                            headerClassName="employee-billing-head seceond_head"
                            header={
                              <table
                                className="employee-billing-task-part"
                                key={emp.employeeCode}
                              >
                                <tr style={{ width: "100%",display:'flex',justifyContent:'space-between' }}>

                                
                                <td className="employee-billing-emp-name" style={{width:'25%'}}>
                                  {emp.fullName}
                                  <span className="emp-bill-empCode">
                                    ({emp.employeeCode})
                                  </span>
                                </td>
                                <td className="totalTrackTime" style={{width:'30%'}}>
                                  {ShowMinToHourAndMin(
                                    "",
                                    Number(emp?.totalTrackTimeInMinute)
                                  )}
                                </td>
                                <td className="billingTime" style={{width:'30%'}}>
                                  {ShowMinToHourAndMin("", billingTime)}
                                </td>
                                </tr>
                              </table>
                            }
                          >
                            {emp?.datesOfTask?.map((date, index) => (
                              <table
                                key={index}
                                className="ebtp employee-billing-task-part p-0 px-4 py-2"
                                style={{ color: mode?.color }}
                              >
                                <tr style={{ width: "100%",display:'flex',justifyContent:'space-between'}}>
                                <span className="ebtpDate" style={{top:'7px'}}>{date.date}</span>
                                <td style={{ width:'25%',paddingLeft:'1.2rem'}}>
                                  {ShowMinToHourAndMin(
                                    "",
                                    Number(date?.totalTrackTimeInMinute)
                                  )}
                                </td>
                                
                                <td style={{width:'30%',padding:0}}>
                                <InputTimeDifference
                                    isloggedIn={emp.isLoggedInUser}
                                    timeInMin={
                                      date?.billingTimeInMinute as number
                                    }
                                    handleSave={(e: any) =>
                                      handleSave(e, {
                                        EmployeeID: emp.id,
                                        EmployeeCode: emp.employeeCode,
                                        EmployeeName: emp.fullName,
                                        TaskID: task.taskId,
                                        TaskName: task.taskName,
                                        TrackTimeInMinute:
                                          date.totalTrackTimeInMinute,
                                        TrackingDate: date.date,
                                      } as InputForCreateOrUpdateEmployeeBillingDto)
                                    }
                                    disabled={userPermissions.includes(updateProjectBillingTime)}
                                  />
                                </td>
                                </tr>
                              </table>
                            ))}
                          </AccordionTab>
                        </Accordion>
                       
                      );
                    })}
                  </AccordionTab>
                </Accordion>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* dialog for add employee *************/}
      <OverlayPanel
        ref={op}
        className="add-employe-dropdown"
        onHide={() => setNewlyAddedEmp(null)} //changed code
      >
        <div className="d-flex flex-column gap-2 w-100">
          <div>
            <Dropdown
              value={newlyAddedEmp}
              onChange={(e) => setNewlyAddedEmp(e.value)}
              placeholder="Select Employee"
              options={employeeOptions}
              className="w-full inner_dropdown"
              style={{ width: "11rem", color: "black" }}
              showClear
              panelStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
            />
          </div>
          <div className="dialog-date-btn-container w-100">
            {dateType === "week" && (
              <DatePicker
                className="employee-custom-datepicker w-100"
                selected={newlyAddedTaskDate}
                onChange={(e) => setNewlyAddedTaskDate(e as Date)}
              />
            )}
          </div>
          <div className="text-center">
            <Button label="Add" onClick={handleNewlyAddedEmp} style={{backgroundColor: ThemeColors?.primaryColor, }}/>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};
