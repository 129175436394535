// export const GetPaginationRowsCount = () => {
//     const height = window.innerHeight;
    
//     if (height > 799 && height <= 1000) {
//         return {
//             rowCount: 20,
//             rowList: [10, 15, 20, 30]
//         };
//     }
//     if (height > 999 && height <= 2160) {
//         return {
//             rowCount: 40,
//             rowList: [10, 40, 15, 20, 30]
//         };
//     }
//     else if (height > 2160 && height <= 3000) {
//         return {
//             rowCount: 45,
//             rowList: [40, 45, 10, 20, 50, 60]
//         };
//     }
//     else if (height > 3000 && height <= 4000) {
//         return {
//             rowCount: 50,
//             rowList: [50, 10, 20, 55, 60, 65]
//         };
//     }
//     else if (height > 4000 && height <= 5000) {
//         return {
//             rowCount: 60,
//             rowList: [60, 10, 20, 50, 30]
//         };
//     }
//     return {
//         rowCount: 5,
//         rowList: [5, 10, 15, 20, 30]
//     };
// }


export const GetPaginationRowsCount = (isDivisibleByFour = false) => {
    const height = window.innerHeight;

    // Function to find the next number divisible by 4
    const getNextDivisibleByFour = (num:number) => {
        const next = num % 4 === 0 ? num : num + (4 - (num % 4));
        return Math.max(next, 12); // Ensure minimum is 12
    };

    // Adjust the row list to be divisible by 4, unique, and limited to 4 elements
    const adjustRowList = (rows:number[]) => {
        const adjusted = rows.map(getNextDivisibleByFour);
        const unique:number[] = [];
        for (const num of adjusted) {
            if (!unique.includes(num)) {
                unique.push(num);
            }
        }
        return unique.slice(0, 4); // Limit to 4 elements
    };

    // Adjust row count and row list based on the condition
    const adjustRows = (rowCount:number, rowList:number[]) => {
        if (isDivisibleByFour) {
            return {
                rowCount: getNextDivisibleByFour(rowCount),
                rowList: adjustRowList(rowList)
            };
        }
        return { rowCount, rowList };
    };

    // Conditions for height ranges
    if (height > 799 && height <= 1000) {
        return adjustRows(20, [10, 15, 20, 30]);
    }
    if (height > 999 && height <= 2160) {
        return adjustRows(40, [10, 40, 15, 20, 30]);
    }
    if (height > 2160 && height <= 3000) {
        return adjustRows(45, [40, 45, 10, 20, 50, 60]);
    }
    if (height > 3000 && height <= 4000) {
        return adjustRows(50, [50, 10, 20, 55, 60, 65]);
    }
    if (height > 4000 && height <= 5000) {
        return adjustRows(60, [60, 10, 20, 50, 30]);
    }
    return adjustRows(5, [5, 10, 15, 20, 30]);
};






