export const skillLogos: Record<string, string> = {
    // JavaScript variations
    javascript: "/skillImages/javaScriptLogo.png",
    js: "/skillImages/javaScriptLogo.png",
  
    // Python variations
    python: "/skillImages/pythonLogo.png",
    py: "/skillImages/pythonLogo.png",

    // nodeJs variations
    nodejs: "/skillImages/nodeJSLogo.png",
    "node js": "/skillImages/nodeJSLogo.png",
    "node.js": "/skillImages/nodeJSLogo.png",
  
    // React variations
    react: "/skillImages/reactLogo.png",
    "react.js": "/skillImages/reactLogo.png",
    "react-js": "/skillImages/reactLogo.png",
    "react js": "/skillImages/reactLogo.png",
    "reactjs": "/skillImages/reactLogo.png",
  
    // React Native variations
    "react native": "/skillImages/reactLogo.png",
    reactnative: "/skillImages/reactLogo.png",
  
    // Angular variations
    angularjs: "/skillImages/Angular_full_color_logo.svg.png",
    angular: "/skillImages/Angular_full_color_logo.svg.png",
    "angular.js": "/skillImages/path/to/angular-logo.png",
    "angular-js": "/skillImages/path/to/angular-logo.png",
  
    // HTML variations
    html: "/skillImages/png-transparent-html-html-logo-html-5-html-five-logo-html-5-logo-programming-langugae-3d-icon-thumbnail.png",
    html5: "/skillImages/png-transparent-html-html-logo-html-5-html-five-logo-html-5-logo-programming-langugae-3d-icon-thumbnail.png",
  
    // CSS variations
    css: "/skillImages/png-transparent-website-web-internet-css-style-css3-technology-social-media-logos-i-flat-colorful-icon-thumbnail.png",
    css3: "/skillImages/png-transparent-website-web-internet-css-style-css3-technology-social-media-logos-i-flat-colorful-icon-thumbnail.png",
  
    // Java variations
    java: "/skillImages/javaLogo.png",
  
    // .NET variations
    ".net": "/skillImages/dotnet.png",
    "dot net": "/skillImages/dotnet.png",
  
    // Flutter variations
    flutter: "/skillImages/png-transparent-flutter-hd-logo-thumbnail.png",
  
    // Kotlin variations
    kotlin: "/skillImages/kotlin.png",
  
    // AWS variations
    aws: "/skillImages/awsLogo.png",
    "amazon web services": "/skillImages/awsLogo.png",
  
    // Azure variations
    azure: "/skillImages/azure.png",
    "microsoft azure": "/skillImages/azure.png",
  
    // Spring Boot variations
    springboot: "/skillImages/springBoot.png",
    "spring boot": "/skillImages/springBoot.png",
  
    // C++ variations
    "c++": "/skillImages/cppLogo.png",
    cpp: "/skillImages/cppLogo.png",
  
    // C variations
    c: "/skillImages/cLogo.png",
  
    // Default for others
    default: "/skillImages/Default_Code_Image.png",
  };
  