import React, { useEffect, useRef, useState } from "react";
import "../../css/components/assignGoal.css";
import "../../css/components/updateGoalSteps.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Themes } from "../../data/Themes";
import { useDispatch, useSelector } from "react-redux";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppModeState } from "../../dtos/common/AppModeState";
import { AppMode } from "../../data/AppMode";
import { SubOrdinateDto } from "../../dtos/goals/SubOrdinatesDto";

import {
  deleteNullValuedSteps,
  getAllStepsByUser,
  getAssignedGoalsByEmp,
  updateGoalStepWithScore,
} from "../../service/goals/goalsService";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { getUserRole } from "../../service/user/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashArrowUp } from "@fortawesome/free-solid-svg-icons";
import { ConfirmDialog } from "primereact/confirmdialog";
import { GoalStepsDto } from "../../dtos/goals/GoalsStepsDto";
import { ADMIN } from "../../shared/constant/RoleNames";

interface AssignGoalsProps {
  showUpdateGoalSteps: boolean;
  setShowUpdateGoalSteps: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGoal: { GoalName: string; EmployeeCode: string } | null;
  selectedSubordinate: SubOrdinateDto | null;
  setSelectedSubordinate: React.Dispatch<
    React.SetStateAction<SubOrdinateDto | null>
  >;
  scorePercentage: number;
  setScorePercentage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedGoal: React.Dispatch<
    React.SetStateAction<{ GoalName: string; EmployeeCode: string } | null>
  >;
  steps: GoalStepsDto[] | [];
  goalDescription: string | null;
  empProgress?: { totalScore: number; totalValue: number };
  setEmpProgress?: React.Dispatch<
    React.SetStateAction<{ totalScore: number; totalValue: number }>
  >;
  setSteps: React.Dispatch<React.SetStateAction<GoalStepsDto[] | []>>;
}

const UpdateGoalSteps: React.FC<AssignGoalsProps> = ({
  showUpdateGoalSteps,
  setShowUpdateGoalSteps,
  selectedGoal,
  selectedSubordinate,
  setSelectedGoal,
  setSelectedSubordinate,
  steps,
  goalDescription,
  setSteps,
  scorePercentage,
  setScorePercentage,
  empProgress,
  setEmpProgress,
}) => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const [inputValues, setInputValues] = useState<number[]>([]);
  const [visitedGoals, setVisitedGoals] = useState<{ [key: number]: boolean }>({
    0: true,
  });
  const [score, setScore] = useState<number>(0);
  const [changedGoalStep, setChangedGoalStep] = useState<{
    StepName: string;
    StepDescription: string;
    Value: number;
    Score: number;
    StepOrderNumber: number;
  } | null>(null);
  const [currentGoalIndex, setCurrentGoalIndex] = useState<number>(0);
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const [givenValue, setGivenValue] = useState<number[] | []>([]);
  const [scoreInDB, setScoreInDB] = useState<number[] | null>(null);

  const [role, setRole] = useState<string>("");
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [stepToDelete, setStepToDelete] = useState<string>("");

  //#endregion

  //#region all Functions

  const showToast = (severity: any, detail: string, summary: string) => {
    toast.current?.show({ severity, summary, detail, life: 3000 });
  };

  const handleIndivisualGoal = (index: number) => {
    setCurrentGoalIndex(index);

    setVisitedGoals((prevState) => ({ ...prevState, [index]: true }));
  };

  const handleNextClick = async () => {
    if (score > givenValue[currentGoalIndex]) {
      showToast("error", "Given value can't be greater than score", "Error");
    } else {
      const currentIndex = currentGoalIndex;
      await handleSave();
      setCurrentGoalIndex((prevIndex) => prevIndex + 1);

      setVisitedGoals((prevState) => ({
        ...prevState,
        [currentIndex + 1]: true,
      }));

      if (steps) {
        if (currentGoalIndex === steps.length - 1) {
          setShowUpdateGoalSteps(false);
          showToast("success", "Updated Goal Successfully", "Success");
          resetUpdateGoalDialog();
        }
      }
    }
  };

  const handleInputChange = (
    index: number,
    event: any,
    triggeredFunc: string
  ) => {
    handleIndivisualGoal(index);
    const newInputValues = [...inputValues];

    // Handle input change for a goal
    if (triggeredFunc === "onInput") {
      newInputValues[index] = parseInt(event.target.value);
      if (event.target.value === "") {
        setScore(0);
      } else {
        setScore(parseInt(event.target.value));
      }
    }

    if (triggeredFunc === "onChange") {
      newInputValues[index] = event.value;
      if (event.value === "") {
        setScore(0);
      } else {
        setScore(event.value);
      }
    }

    setInputValues(newInputValues);
  };

  const handleSave = async () => {
    if (selectedGoal && selectedSubordinate && changedGoalStep) {
      const data = {
        GoalName: selectedGoal?.GoalName,
        EmployeeCode: selectedSubordinate?.employeeCode,
        StepName: changedGoalStep?.StepName,
        CompletionTime: new Date().toISOString(),
        Value: score,
        OrderNumber: steps[currentGoalIndex].StepOrderNumber,
      };

      const res = await updateGoalStepWithScore(data);
      if (res.data.Result.error) {
        showToast("error", res.data.Result.msg, "Error");
      }
      const allGoals = await getAssignedGoalsByEmp(dispatch, {
        goalName: selectedGoal?.GoalName,
        empCode: selectedGoal?.EmployeeCode,
      });
      setScorePercentage(allGoals.UserGoalStepScorePercentage);
      setEmpProgress &&
        setEmpProgress({
          totalValue: allGoals.totalValue,
          totalScore: allGoals.totalScore,
        });
      setSteps(allGoals.GoalSteps);
    }
  };

  const resetUpdateGoalDialog = () => {
    setSelectedGoal(null);
    setSelectedSubordinate(null);
    setInputValues([]);
    setVisitedGoals({ 0: true });
    setScorePercentage(0);
    setCurrentGoalIndex(0);
  };

  const scoreSetter = () => {
    const isVisited = visitedGoals;

    const scoreArr = steps.map((goal) => {
      return goal?.Value;
    });
    const givenValueArr = steps.map((goal) => {
      return goal?.Score;
    });

    setScoreInDB(scoreArr);
    setGivenValue(givenValueArr);
    scoreArr?.map((score, i) => {
      if (score !== null && score !== undefined) {
        isVisited[i] = true;
      } else {
        isVisited[i] = false;
      }
    });

    setVisitedGoals(isVisited);

    const currentIndex = scoreArr?.findIndex(
      (value, index) => value === null || value === undefined
    );

    if (currentIndex !== -1) {
      setVisitedGoals({ ...visitedGoals, [currentIndex!]: true });
      setCurrentGoalIndex(currentIndex!);
    } else {
      setCurrentGoalIndex(-1);
    }

    if (scoreArr) {
      setInputValues(scoreArr);
    }
  };

  const reject = () => {
    showToast("warn", "You have rejected the delete operation", "Rejected");
    setShowConfirmDialog(false);
  };

  const accept = async (stepName: string) => {
    await deleteStepsForIndivisualUser(stepName);

    setShowConfirmDialog(false);
  };

  const fetchRole = async () => {
    const roleData = await getUserRole(dispatch);
    setRole(roleData);
  };

  const deleteStepsForIndivisualUser = async (stepName: string) => {
    const input = {
      GoalName: selectedGoal?.GoalName,
      StepName: stepName,
      role,
      EmployeeCode: selectedGoal?.EmployeeCode,
    };

    await deleteNullValuedSteps(dispatch, input);
    if (selectedGoal) {
      const inputForSteps = {
        GoalName: selectedGoal?.GoalName,
        EmployeeCode: selectedGoal?.EmployeeCode,
      };
      const updatedSteps = await getAllStepsByUser(dispatch, inputForSteps);
      setSteps(updatedSteps.GoalSteps);
    }

    showToast(
      "success",
      `Goal step for ${selectedSubordinate?.fullName} deleted successfully`,
      "Success"
    );
  };

  //#endregion

  //#region all Templates

  const assignGoalsHeaderTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
        <div className="goal_popup_info">
          <h1 style={{ fontSize: "20px", fontWeight: "700" }}>
            {localization?.UpdateGoalSteps || "Update Goal Steps"}
          </h1>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="toal_done_work d-flex justify-content-center align-items-center">
            <span className="progress-key text-success">
              {localization?.Progress || "Progress"}:
            </span>
            <span className="progress-value text-success">
              {empProgress && empProgress.totalValue}/
              {empProgress && empProgress.totalScore}
            </span>
          </div>
          <div className="toal_done_work d-flex justify-content-center align-items-center">
            <span className="score-key text-warning">{localization?.Score || "Score"}:</span>
            <span className="score-value text-warning">{Math.round(scorePercentage)}%</span>
          </div>
        </div>
      </div>
    );
  };

  const addGoalFooterTemplate = () => {
    const goalStepsLength = steps.length ?? 0;
    const isSubmitDisabled = currentGoalIndex !== goalStepsLength - 1;
    let isNextToShow;
    if (scoreInDB) {
      isNextToShow = scoreInDB.every((value) => value !== null);
    }

    return (
      <div className="form-actions">
        <Button
          className="modalsecondaryBtn text-primary"
          type="button"
          style={{
            backgroundColor: mode?.backgroundPrimaryColor,
            // border: `1px solid ${modeName === "light" ? "#970FFF" : "white"}`,
          }}
          onClick={() => {
            setShowUpdateGoalSteps(false);
            resetUpdateGoalDialog();
          }}
        >
          {localization?.Cancel || "Cancel"}
        </Button>

        {!isNextToShow && (
          <Button
            className="modalprimaryBtn"
            label={
              steps && currentGoalIndex === steps.length - 1
                ? localization?.Submit || "Submit"
                : localization?.Next || "Next"
            }
            icon={
              steps && currentGoalIndex !== steps.length - 1
                ? "pi pi-arrow-right"
                : ""
            }
            iconPos="right"
            onClick={handleNextClick}
            disabled={
              inputValues[currentGoalIndex] === undefined ||
              inputValues[currentGoalIndex] === null ||
              inputValues[currentGoalIndex] < 0
            }
          />
        )}

        
      </div>
    );
  };

  //#endregion

  useEffect(() => {
    fetchRole();
  }, []);

  useEffect(() => {
    scoreSetter();
  }, [steps]);

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
        message={`Are you sure you want to delete ${stepToDelete}?`}
        header="Delete Confirmation"
        acceptClassName="p-button-danger"
        icon="pi pi-exclamation-triangle"
        accept={() => accept(stepToDelete)}
        reject={reject}
      />
      <Dialog
        className="user_goal_dialog"
        header={assignGoalsHeaderTemplate}
        footer={addGoalFooterTemplate}
        visible={showUpdateGoalSteps}
        style={{
          width: "50vw",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        onHide={() => {
          if (!showUpdateGoalSteps) return;
          setShowUpdateGoalSteps(false);
          resetUpdateGoalDialog();
        }}
        draggable={false}
      >
        <div
          className="goal-form p-0"
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: mode?.backgroundSecondaryColor,
            color: mode?.color,
          }}
        >
          <div
            className="d-flex justify-content-between lightBg p-2 px-4 mb-3"
            style={{
              // display: "flex",
              // justifyContent: "space-between",
              // backgroundColor: mode?.backgroundSecondaryColor,
              // color: mode?.color,
            }}
          >
            <div className="employee-name-container">
              <label htmlFor="goal-name" className="employee-with-name">
                {localization?.EmployeeName || "Employee Name"}:
              </label>
              <span className="">{selectedSubordinate?.fullName}</span>
              {/* <input
                style={{
                  border: "none",
                  borderBottom: "1px solid transparent",
                  // width: "50%",
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
                value={selectedSubordinate?.fullName}
                disabled
              /> */}
            </div>
            <div className="goal-name-container d-flex align-items-center md:justify-content-end">
              <div className="w-16rem m-0 text-end">
                  <label htmlFor="duration" className="goal-with-name">
                    {localization?.GoalName || "Goal Name"}:
                  </label>
                  <span className="d-inline-block">{selectedGoal?.GoalName}</span>
              </div>
              
              {/* <input
                className="text-end w-auto d-inline-block"
                style={{
                  border: "none",
                  borderBottom: "1px solid transparent",
                  // width: "50%",
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
                value={selectedGoal?.GoalName}
                disabled
              /> */}
            </div>
          </div>
          {goalDescription && (
            <div className="mx-4">
              <p className="goal-with-description">
                {localization?.GoalDescription || "Goal Description"}
              </p>
              <p>{goalDescription ? goalDescription : ""}</p>
            </div>
          )}

          <div className="mx-4 mt-4">
            <label htmlFor="goal-steps" className="goal-steps">
              {localization?.GoalSteps || "Goal Steps"}
            </label>
            <div className="flex flex-column goal-wrapper upgrate_goal_wrapper mt-3">
              {steps.map((elem, i) => {
                const isVisited = !!visitedGoals[i];

                const buttonColor =
                  currentGoalIndex === i
                    ? "#ecb330"
                    : 
                    scoreInDB !== null &&
                      scoreInDB[i] !== null &&
                      scoreInDB[i] !== undefined
                    ? "green"
                    : "gray";

                return (
                  <>
                    <div className="goal-step" style={{ width: "100%" }}>
                      <Button
                        className="justify-content-center border-0"
                        style={{ backgroundColor: buttonColor }}
                      >
                        {i + 1}
                      </Button>

                      <div
                        className="flex flex-column goal-content w-100 ps-3"
                      >
                        <div className="d-flex justify-content-between align-items-center border border-gray-300 rounded-3 px-2 py-1">
                          <div className="step-name-container">
                            <span
                              style={{
                                color: visitedGoals[i] ? mode?.color : "gray",
                              }}
                            >
                              {elem.StepName}
                            </span>
                          </div>
                          <div className="step-description-and-score-container">
                            {currentGoalIndex === i ? (
                              <>
                                <InputNumber
                                  inputId="integeronly"
                                  value={inputValues[i]}
                                  name="Score"
                                  min={0}
                                  onInput={(e) => {
                                    handleInputChange(i, e, "onInput");
                                    setChangedGoalStep(elem);
                                  }}
                                  onChange={(e: any) => {
                                    handleInputChange(i, e, "onChange");
                                    setChangedGoalStep(elem);
                                  }}
                                  inputClassName="value-input-style"
                                  inputStyle={{
                                    border: `${
                                      inputValues[i] > elem.Score
                                        ? "1px solid red"
                                        : "1px solid gray"
                                    }`,
                                  }}
                                />

                                <span className="px-2" style={{ fontSize: "16px" }}>/</span>

                                <InputNumber
                                  inputId="integeronly"
                                  value={elem.Score}
                                  name="Score"
                                  onChange={(e) => {
                                    setChangedGoalStep(elem);
                                  }}
                                  inputClassName="score-input-style"
                                  disabled
                                />
                              </>
                            ) : (
                              <>
                                <InputNumber
                                  inputId="integeronly"
                                  value={inputValues[i]}
                                  name="Score"
                                  onChange={(e) => {
                                    setChangedGoalStep(elem);
                                  }}
                                  inputClassName="score-input-style"
                                  disabled
                                />
                                <span className="px-2" style={{ fontSize: "16px" }}>/</span>

                                <InputNumber
                                  inputId="integeronly"
                                  value={elem.Score}
                                  name="Score"
                                  onChange={(e) => {
                                    setChangedGoalStep(elem);
                                  }}
                                  inputClassName="score-input-style"
                                  disabled
                                />
                              </>
                            )}
                            {role === ADMIN && (
                              <>
                                <Button
                                  onClick={() => {
                                    setStepToDelete(elem.StepName);
                                    setShowConfirmDialog(true);
                                  }}
                                  className="score-delete-button"
                                  disabled={inputValues[i] != null}
                                >
                                  <FontAwesomeIcon icon={faTrashArrowUp} />
                                </Button>
                              </>
                            )}
                          </div>
                          {i < steps.length - 1 && (
                            <div className="goal-connector"></div>
                          )}
                        </div>
                        <div
                          style={{
                            padding: "6px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              color: visitedGoals[i] ? mode?.color : "gray",
                            }}
                          >
                            {elem.StepDescription}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          
        </div>
      </Dialog>
    </>
  );
};

export default UpdateGoalSteps;
