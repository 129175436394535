import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import * as react from 'react';
import '../../css/components/systemAdmin.css';
import { useEffect, useRef, useState } from 'react';
import { findSettingsByKey, updateOrCreateByKey, updateSettings } from '../../service/common/commonService';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateSettings } from '../../dtos/common/UpdateSettingsDto';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { AppMode } from '../../data/AppMode';
import { Themes } from '../../data/Themes';
import { AppModeState } from '../../dtos/common/AppModeState';
import { ThemeState } from '../../dtos/common/ThemeState';

export const SystemAdmin = () => {
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const dispatch = useDispatch();
    const toast = useRef<any>(null);
    const themeName = useSelector((state: ThemeState) => state.theme.themeName);
    const ThemeColors = Themes.find(th => th.name === themeName);
    const modeName = useSelector((state: AppModeState) => state.theme.appMode);
    const mode = AppMode.find(md => md.name === modeName);

    const handleSettingsData = async () => {
        const adminEmail = await findSettingsByKey(dispatch, { key: 'adminEmail' });
        setEmail(adminEmail.Value);
        const adminPwd = await findSettingsByKey(dispatch, { key: 'adminPassword' });
        setPwd(adminPwd.Value);
    }
    
    const changeCredential = () => {
        confirmDialog({
            message: 'Are you sure you want to change the admin credential?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
        });
    };

    const accept = async () => {
        const newEmail: UpdateSettings = {
            Key: 'adminEmail',
            Value: email
        }

        const newPwd: UpdateSettings = {
            Key: 'adminPassword',
            Value: pwd
        }
        try {
            await updateOrCreateByKey(dispatch, newEmail);
            await updateOrCreateByKey(dispatch, newPwd);
            toast.current.show({ severity: 'success', summary: 'Great!', detail: "You have successfully change the admin credentials" });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error!', detail: 'Sorry there is some error for change the admin credentials', life: 3000 });
        }
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected to change the admin credentials', life: 3000 });
    }


    useEffect(() => {
        handleSettingsData()
    }, [])

    return (
        <div className='systemAdminCont'>
            <Toast ref={toast} />
            <ConfirmDialog style={{ backgroundColor: 'white' }} />
            <Card className='' style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }}>
                <h3 className='admin-cred-head'>Admin Credentials</h3>
                <div className='admin-cred-container'>
                    <InputText value={email} style={{width:'18rem'}} onChange={e => setEmail(e.target.value)} />
                    <Password value={pwd} inputStyle={{width:'18rem'}} onChange={e => setPwd(e.target.value)} />
                    <Button label='Change Credential' onClick={changeCredential} style={{ backgroundColor: ThemeColors?.primaryColor, border: 'none' }} className='credential_btn' />
                </div>
            </Card>
        </div>
    )
}