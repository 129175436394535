import {
  faCheck,
  faCircleCheck,
  faCircleMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "../../css/components/assignToEmployees.css";
import { useDispatch, useSelector } from "react-redux";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { ThemeState } from "../../dtos/common/ThemeState";
import { AppMode } from "../../data/AppMode";
import { AssignGoalUserDto } from "../../dtos/userGoals/AssignGoalUserDto";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { getAssignedGoalsByEmp } from "../../service/goals/goalsService";
import { ProgressDto } from "../../dtos/goals/ProgressDto";
import UpdateGoalSteps from "./UpdateGoalSteps";
import { SubOrdinateDto } from "../../dtos/goals/SubOrdinatesDto";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { GoalStepsDto } from "../../dtos/goals/GoalsStepsDto";
import { formatInitials } from "../../shared/functions/FormatInitials";
import { getRandomColor } from "../../shared/functions/GetRandomColour";
interface AssignToEmployeesProps {
  allAssignedGoalUser: AssignGoalUserDto[];
  showAssignToEmployee: boolean;
  setShowAssignToEmployee: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedGoalName: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedGoalId: React.Dispatch<React.SetStateAction<number | null>>;
  progress: { steps: ProgressDto[]; totalCount: number };
  addGoalFooterTemplate: () => any;
  totalAssignedEmployee: number;
  resetPaginator: () => void;
  selectedGoalName: string | null;
  loading: boolean;
}

const AssignToEmployees: React.FC<AssignToEmployeesProps> = ({
  showAssignToEmployee,
  setShowAssignToEmployee,
  setSelectedGoalName,
  setSelectedGoalId,
  progress,
  addGoalFooterTemplate,
  resetPaginator,
  selectedGoalName,
  loading,
}) => {
  //#region all Variables
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const [scorePercentage, setScorePercentage] = useState<number>(0);
  const [empProgress, setEmpProgress] = useState<{
    totalValue: number;
    totalScore: number;
  }>({ totalValue: 0, totalScore: 0 });

  const dispatch = useDispatch();
  const [showUpdateGoalSteps, setShowUpdateGoalSteps] =
    useState<boolean>(false);
  const [selectedGoal, setSelectedGoal] = useState<{
    GoalName: string;
    EmployeeCode: string;
  } | null>(null);
  const [selectedSubordinate, setSelectedSubordinate] =
    useState<SubOrdinateDto | null>(null);
  const [steps, setSteps] = useState<
    | GoalStepsDto[]
    | []
  >([]);
  const [goalDescription, setGoalDescription] = useState<string | null>(null);
  const items: any = Array.from({ length: 5 }, (v, i) => i);

  //#endregion

  //#region all Functions

  const getStepsByGoalNameAndEmpCode = async () => {
    const allGoals = await getAssignedGoalsByEmp(dispatch, {
      goalName: selectedGoal?.GoalName,
      empCode: selectedGoal?.EmployeeCode,
    });
    setScorePercentage(allGoals.UserGoalStepScorePercentage);
    setSteps(allGoals.GoalSteps);
    setGoalDescription(allGoals.GoalDescription);
    setEmpProgress({
      totalValue: allGoals.totalValue,
      totalScore: allGoals.totalScore,
    });
  };

  const   getStatusStyle = (status: string) => {
    if (status.toLowerCase() === "ongoing") {
      return { backgroundColor: "transparent", color:'#FF9D00', width:'90px' };
    } else if (status.toLowerCase() === "done") {
      return { backgroundColor: "transparent",color: "#12982A",width:'90px'  };
    }
    return {};
  };

  const showUpdateDialog = async (
    employeeCode: string,
    employeeName: string
  ) => {
    if (selectedGoalName) {
      setSelectedGoal({
        GoalName: selectedGoalName,
        EmployeeCode: employeeCode,
      });
      setSelectedSubordinate({
        employeeCode: employeeCode,
        fullName: employeeName,
      });
    }
  };

  const openUpdateDialog = async () => {
    setShowAssignToEmployee(false);
    await getStepsByGoalNameAndEmpCode();
    setShowUpdateGoalSteps(true);
  };

  //#endregion

  //#region all Templates

  const assignToEmployeeHeaderTemplate = () => {
    return (
      <div className="assign-to-employees-header">
        <h1 className="m-0" style={{ fontSize: "18px" }}>
        {localization?.AssignedEmployees || "Assigned Employees"}
        </h1>
      </div>
    );
  };

  //#endregion

  useEffect(() => {
    if (selectedSubordinate) {
      openUpdateDialog();
    }
  }, [selectedSubordinate]);

  return (
    <>
      <Dialog
        draggable={false}
        header={assignToEmployeeHeaderTemplate}
        footer={addGoalFooterTemplate}
        visible={showAssignToEmployee}
        style={{
          width: "35vw",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        onHide={() => {
          if (!showAssignToEmployee) return;
          setShowAssignToEmployee(false);
          setSelectedGoalName(null);
          setSelectedGoalId(null);
          resetPaginator();
        }}
        maskStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="assigned_Employees"
      >
        <div>
          {loading ? (
            <DataTable value={items} className="assigned_EmployeesTable p-datatable-striped">
              <Column
                field="Name"
                header={localization?.Name || "Name"}
                body={<Skeleton />}
                bodyStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              ></Column>
              <Column
                field="Progress"
                header={localization?.Progress || "Progress"}
                body={<Skeleton />}
                bodyStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              ></Column>
              <Column
                field="Status"
                header={localization?.Status || "Status"}
                body={<Skeleton />}
                bodyStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              ></Column>
              <Column
                field="Actions"
                header={localization?.Actions || "Actions"}
                body={<Skeleton />}
                bodyStyle={{
                  backgroundColor: mode?.backgroundSecondaryColor,
                  color: mode?.color,
                }}
              ></Column>
            </DataTable>
          ) : progress.steps.length > 0 ? (
            <table
            className="assign-to-employees-table1 assignToEmployeeTable"
            >
              <tr>
                <th>{localization?.Name || "Name"}</th>
                <th>{localization?.Progress || "Progress"}</th>
                <th>{localization?.Status || "Status"}</th>
                <th>{localization?.Actions || "Actions"}</th>
              </tr>
              {progress.steps.map((empProgress) => {
                const status =
                  empProgress.totalscore === empProgress.totalvalue
                    ? "done"
                    : "ongoing";

                const totalValue = empProgress.totalvalue
                  ? empProgress.totalvalue
                  : 0;
                const totalScore = empProgress.totalscore
                  ? empProgress.totalscore
                  : 0;
                return (
                  <tr key={empProgress.employeecode}>
                    <td>
                       <div className="d-flex align-items-center ">
                            <span className="w-50px w-3rem font-bold text-center p-component1 rounded-3 fs-5 p-2 me-2" style={{backgroundColor:getRandomColor(),color:"#fff"}}>{formatInitials(empProgress.employeename)}</span> 
                            <span className="progessEmpName w-15rem flex-1">{empProgress.employeename} ({empProgress.employeecode})</span>
                          </div>
                      
                    </td>
                    <td>
                      <span>{totalValue}</span>
                      <span>/</span>
                      <span>{empProgress.totalscore}</span>
                    </td>
                    <td >
                      {status==="done"?
                      <Button  className="border-0 p-0" style={getStatusStyle(status)}>
                        <FontAwesomeIcon icon={faCheck} style={{color:getStatusStyle(status).color,marginRight:'5px'}}/>
                        Done
                        </Button>:
                        <Button className="border-0 text-warning p-0" style={getStatusStyle(status)}>
                          Ongoing
                        </Button>
                        }
                     
                    </td>
                    <td>
                      <Button
                      className="w-30px px-3 border-0 text-primary"
                         style={{ backgroundColor:"transparent" }}
                        onClick={() =>
                          showUpdateDialog(
                            empProgress.employeecode,
                            empProgress.employeename
                          )
                        }
                      >
                        <i
                          className="pi pi-arrow-up-right"
                          style={{ fontSize: "0.9rem" }}
                        ></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </table>
          ) : (
            "This goal currently has no assigned employees."
          )}
        </div>
      </Dialog>
      <UpdateGoalSteps
        showUpdateGoalSteps={showUpdateGoalSteps}
        setShowUpdateGoalSteps={setShowUpdateGoalSteps}
        selectedGoal={selectedGoal}
        selectedSubordinate={selectedSubordinate}
        setSelectedGoal={setSelectedGoal}
        setSelectedSubordinate={setSelectedSubordinate}
        steps={steps}
        setSteps={setSteps}
        goalDescription={goalDescription}
        scorePercentage={scorePercentage}
        setScorePercentage={setScorePercentage}
        empProgress={empProgress}
        setEmpProgress={setEmpProgress}
      />
    </>
  );
};

export default AssignToEmployees;
