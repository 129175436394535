// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-toolbar {
    position: relative;
}

.endToolbarProject {
    position: absolute;
    right: 1rem;
    bottom: 0rem;
    display: flex;
    gap: 0.5rem;
}


.project-toolbar-button {
    color: gray;
    background-color: transparent;
    border: none;
    padding: 0.3rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px 4px 0px 0px;
}

.project-toolbar-button:hover,.selected {
    color: black;
    background-color: #d6d6d6;
}

.p-datatable>.p-datatable-wrapper{
    max-height: calc(100vh - 260px );
    overflow: auto;
}
@media only screen and (max-width:575px){
    .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last{
        min-width: 2rem;
        height: 2rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/components/project.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,WAAW;AACf;;;AAGA;IACI,WAAW;IACX,6BAA6B;IAC7B,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;IAChC,cAAc;AAClB;AACA;IACI;QACI,eAAe;QACf,YAAY;IAChB;AACJ","sourcesContent":[".project-toolbar {\n    position: relative;\n}\n\n.endToolbarProject {\n    position: absolute;\n    right: 1rem;\n    bottom: 0rem;\n    display: flex;\n    gap: 0.5rem;\n}\n\n\n.project-toolbar-button {\n    color: gray;\n    background-color: transparent;\n    border: none;\n    padding: 0.3rem 1rem;\n    font-size: 1rem;\n    cursor: pointer;\n    border-radius: 4px 4px 0px 0px;\n}\n\n.project-toolbar-button:hover,.selected {\n    color: black;\n    background-color: #d6d6d6;\n}\n\n.p-datatable>.p-datatable-wrapper{\n    max-height: calc(100vh - 260px );\n    overflow: auto;\n}\n@media only screen and (max-width:575px){\n    .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last{\n        min-width: 2rem;\n        height: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
