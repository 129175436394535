import { Card } from "primereact/card";
import { useEffect, useRef, useState } from "react";
import { UserMappingDto } from "../../dtos/userMappings/UserMappingDto";
import { useDispatch, useSelector } from "react-redux";
import { getAllKpiMappingsByUser } from "../../service/userMappings/userMappingsService";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { AppMode } from "../../data/AppMode";
import { Themes } from "../../data/Themes";
import { AppModeState } from "../../dtos/common/AppModeState";
import { ThemeState } from "../../dtos/common/ThemeState";
import { Button } from "primereact/button";
import { Kpis } from "../../dtos/kpiMappings/GetAllKpiMappingsDto";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { getSubordinates } from "../../service/employee/employeeService";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { EmployeeDto } from "../../dtos/employee/EmployeeDto";
import { KPIItemsDto } from "../../dtos/kpi/KPIItemsDto";
import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import { getAllActiveKpi } from "../../service/kpi/kpiService";
import {
  CreateKPIMappings,
  KPIInnputs,
} from "../../dtos/kpiMappings/CreateKPIMappingsDto";
import {
  createKpiMappings,
  createUserMappings,
  deleteKpiMappingsByEmployeeCode,
  getKpiMappingsByUser,
} from "../../service/kpiMappings/kpiMappingsService";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { GetKpiMappingsByUserInputDto } from "../../dtos/kpiMappings/GetKpiMappingsByUserInputDto";
import { KpiMappingsByUserDto } from "../../dtos/kpiMappings/KpiMappingsByUserDto";
import { isPermissionGiven } from "../../shared/functions/HasPermission";
import { createUserMapping, editUserMapping } from "../../shared/constant/PermissionVariables";
import { GetPaginationRowsCount } from "../../shared/functions/GetPaginationRowsCount";
import { GetPaginationRowsInterface } from "../../dtos/common/GetPaginationRows";

export const UserMappings = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<UserMappingDto[]>([]);
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find((md) => md.name === modeName);
  const [searchText, setSearchText] = useState<string>("");
  const [kpiMappingsDialog, setKpiMappingsDialog] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(true);
  const [users, setUsers] = useState<EmployeeDto[]>([]);
  const [selectedUser, setSelectedUser] = useState<EmployeeDto | null>();
  const [reqUserErr, setReqUserErr] = useState(false);
  const [allKpiToShow, setAllKpiToShow] = useState<KPIItemsDto[]>([]);
  const [selectKpiDialog, setSelectKpiDialog] = useState<boolean>(false);
  const [allKPI, setAllKpi] = useState<KPIItemsDto[]>([]);
  const [sliderValues, setSliderValues] = useState(allKPI.map((kp) => 50));
  const [targetValues, setTargetValues] = useState(allKPI.map((kp) => 50));
  const validWeightage = new RegExp("^(?:[0-9][0-9]?|100)$");
  const [errorIndex, setErrorIndex] = useState<number[]>([]);
  const [kpis, setKpis] = useState<KPIItemsDto[]>([]);
  const [kpiList, setKpiList] = useState<KPIItemsDto[]>([]);
  const [labelRoleData, setLabelRoleData] = useState<string>("");
  const userOptions = users?.map((emp) => ({
    label: `${emp.fullName} (${emp.employeeCode})`,
    value: emp, // You can set the entire employee object as the value if needed
  }));
  const [searckKPI, setSearchKPI] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedKPI, setSelectedKPI] = useState<any[] | null>(null);
  const [allUsers, setAllUsers] = useState<EmployeeDto[]>([]);
  const toast = useRef<any>(null);
  const localization = useSelector(
    (state: any) => state.localization.localization
  );
  const userPermissions = useSelector((state: any) => state.permission.permissions);
  const row: GetPaginationRowsInterface = GetPaginationRowsCount();
  // #region all Functions

  const getAllUserMappings = async () => {
    const userMapping: UserMappingDto[] = await getAllKpiMappingsByUser(
      dispatch
    );
    if (searchText.length > 0) {
      const searchedData = data?.filter((sData: UserMappingDto) => {
        const matchingKPIs = sData.Kpis.some((kpData: Kpis) => {
          return kpData?.KPI.Name?.toLowerCase()?.includes(
            searchText.toLowerCase()
          );
        });
        return (
          matchingKPIs ||
          sData?.EmployeeName?.toLowerCase()?.includes(searchText.toLowerCase())
        );
      });
      setData(searchedData);
      return;
    }
    setData(userMapping);
  };

  const getAllKpis = async () => {
    const res: KPIItemsDto[] = await getAllActiveKpi(dispatch, null);
    setAllKpi(res);
  };

  const handleCreate = async () => {
    const hasPermission = isPermissionGiven(createUserMapping, userPermissions, dispatch); 
    if(!hasPermission){
      return 
    }
    setShowDropdown(true);
    setKpiMappingsDialog(true);
  };

  const getAllUsers = async () => {
    const usersData: EmployeeDto[] = await getSubordinates(dispatch);

    const filteredUsers: EmployeeDto[] = [];
    usersData?.forEach((user) => {
      const isPresent = data.find(
        (val) => val.EmployeeCode === user.employeeCode
      );
      if (!isPresent) {
        filteredUsers.push(user);
      }
    });
    setUsers(filteredUsers);
    setAllUsers(usersData);
  };

  const getRoleMappings = async (roleName: string[]) => {
    const input: GetKpiMappingsByUserInputDto = {
      roleName: roleName,
    };
    const allKpisData: KpiMappingsByUserDto[] = await getKpiMappingsByUser(
      dispatch,
      input
    );
    setAllKpiToShow(allKpisData);

    const weightageVal: number[] = [];
    const targetVal: number[] = [];

    allKpisData.forEach((kpi) => {
      weightageVal.push(kpi.Weightage);
      targetVal.push(kpi.Target);
    });

    setSliderValues(weightageVal);
    setTargetValues(targetVal);
  };

  const handleSearchKPI = () => {
    const searchedKpis = allKPI.filter((kpi) => {
      return kpi.Name.toLowerCase().includes(searckKPI.toLowerCase());
    });
    if (searchedKpis.length === 0) {
      setKpis([]);
      return;
    }
    setKpis(searchedKpis);
  };

  const hideDialog = () => {
    setSliderValues([]);
    setTargetValues([]);
    setErrorIndex([]);
    setKpiMappingsDialog(false);
    setSelectedUser(null);
    setReqUserErr(false);
    setIsEdit(false);
    setKpiList([]);
    setSearchKPI("");
    setKpis([]);
    setAllKpiToShow([]);
  };

  const handleSliderChange = (index: number, value: any) => {
    if (!validWeightage.test(value)) {
      setErrorIndex((prevErrorIndex) => [...prevErrorIndex, index]);
    } else {
      setErrorIndex((prevErrorIndex) =>
        prevErrorIndex.filter((val) => val !== index)
      );
    }
    const newSliderValues = [...sliderValues];
    newSliderValues[index] = value;
    setSliderValues(newSliderValues);
  };

  const handleTargetSliderChange = (index: number, value: any) => {
    if (!validWeightage.test(value)) {
      setErrorIndex((prevErrorIndex) => [...prevErrorIndex, index]);
    } else {
      setErrorIndex((prevErrorIndex) =>
        prevErrorIndex.filter((val) => val !== index)
      );
    }
    const newTargetValues = [...targetValues];
    newTargetValues[index] = value;
    setTargetValues(newTargetValues);
  };

  const handleAddKpi = () => {
    setKpis(allKPI);
    setSelectKpiDialog(true);
  };

  const hideSelectKpiDialog = () => {
    setSelectKpiDialog(false);
    setSearchKPI("");
  };

  const handleKPIList = (kpi: KPIItemsDto) => {
    setKpiList((prev) => {
      const newList = [...prev];
      const existingIndex = newList.findIndex(
        (item: any) => item.id === kpi.id
      );
      if (existingIndex !== -1) {
        // If kpi exists, remove it
        newList.splice(existingIndex, 1);
        return newList;
      }
      newList.push(kpi);
      return newList;
    });
  };

  const saveKpis = () => {
    setAllKpiToShow((prev) => {
      const newList = [...prev];
      kpiList.map((kpis) => {
        const existingIndex = newList.findIndex(
          (item: any) => item.id === kpis.id
        );
        if (existingIndex !== -1) {
          // If kpi exists, remove it
          return newList;
        }
        newList.push(kpis);
      });

      const defaultWeightageVal = Math.floor(100 / newList.length); // Using Math.floor to ensure whole number
      let remainingWeight = 100; // Initialize remaining weight
      const sliderValues: number[] = []; // Initialize slider values array

      newList.forEach((kp, index) => {
        // Calculate weightage for each KPI
        const weight =
          index === newList.length - 1 ? remainingWeight : defaultWeightageVal; // Ensure the last item takes the remaining weight
        sliderValues.push(weight); // Push weight to sliderValues
        remainingWeight -= weight; // Update remaining weight
      });

      setSliderValues(sliderValues); // Set slider values

      let targetVal: number[] = [];
      newList.forEach((kp) => targetVal.push(50));

      setTargetValues(targetVal); // Set target values

      return newList;
    });

    setKpiList([]);
    setKpis([]);
    setSearchKPI("");
    setSelectKpiDialog(false);
  };

  const handleEdit = async (rowData: UserMappingDto) => {
    const hasPermission = isPermissionGiven(editUserMapping, userPermissions, dispatch); 
    if(!hasPermission){
      return 
    }
    setIsEdit(true);
    setShowDropdown(false);
    const labelData = allUsers?.find((user: EmployeeDto) => {
      return user.employeeCode === rowData.EmployeeCode;
    });
    if (labelData) {
      setSelectedUser(labelData);
      setLabelRoleData(labelData.fullName + `(${labelData.employeeCode})`);
    }
    setKpiMappingsDialog(true);

    rowData.Kpis.find((kpi: Kpis) => {
      const findKpi = allKPI.find((kp) => kp.id === kpi.KPI.id);
      if (typeof findKpi !== "undefined") {
        setAllKpiToShow((prev) => {
          const newKpi = [...prev];
          newKpi.push(findKpi);
          return newKpi;
        });
      }
    });

    rowData.Kpis.forEach((kpi, index) => {
      setSelectedKPI((prev: any) => {
        // Ensure prev is an array
        const next = Array.isArray(prev) ? [...prev] : [];
        next.push({
          index: index,
          kpi: kpi.KPI,
        });
        return next;
      });
      setSliderValues((prev: any) => {
        // Ensure prev is an array
        const next = Array.isArray(prev) ? [...prev] : [];
        if (kpi.Weightage === null) {
          next[index] = 0;
        } else {
          next[index] = kpi.Weightage;
        }
        return next;
      });
      setTargetValues((prev: any) => {
        // Ensure prev is an array
        const next = Array.isArray(prev) ? [...prev] : [];
        if (kpi.Target === null) {
          next[index] = 0;
        } else {
          next[index] = kpi.Target;
        }
        return next;
      });
    });
  };

  const handleDeleteKpiMapp = (kpi: KPIItemsDto) => {
    const kpis = allKpiToShow.filter((kp) => {
      return kp.id !== kpi.id;
    });

    const defaultWeightageVal = Math.floor(100 / kpis.length); // Using Math.floor to ensure whole number
    let remainingWeight = 100; // Initialize remaining weight
    const sliderValues: number[] = []; // Initialize slider values array

    kpis.forEach((kp, index) => {
      // Calculate weightage for each KPI
      const weight =
        index === kpis.length - 1 ? remainingWeight : defaultWeightageVal; // Ensure the last item takes the remaining weight
      sliderValues.push(weight); // Push weight to sliderValues
      remainingWeight -= weight; // Update remaining weight
    });

    setSliderValues(sliderValues); // Set slider values
    setTargetValues(sliderValues); // Set slider values

    setAllKpiToShow(kpis);
  };

  const handleSave = async () => {
    const kpiInputs: KPIInnputs[] = [];
    const isEmptySliderVal: boolean[] = [];
    const isEmptyTargetVal: boolean[] = [];

    const input: KPIInnputs[] = [];
    allKpiToShow?.forEach((kpiItems: KPIItemsDto, index: number) => {
      let findSliderVal = sliderValues.find((val, i) => i === index);
      let findTargetVal = targetValues.find((val, i) => i === index);

      //if there is no value then make it 0 and for messahe inser false in isEmptySliderVal
      if (findSliderVal === undefined) {
        findSliderVal = 0;
        isEmptySliderVal.push(false);
      }

      //if there is no value in target then make it 0 and for messahe inser false in isEmptySliderVal
      if (findTargetVal === undefined) {
        findTargetVal = 0;
        isEmptySliderVal.push(false);
        isEmptyTargetVal.push(false);
      }

      input.push({
        KPIID: kpiItems.id,
        Weightage: findSliderVal,
        Target: findTargetVal,
      });
    });
    input.forEach((val) => {
      if (val.Weightage !== null) {
        kpiInputs.push(val);
      }
    });
    const totalWeightage = kpiInputs.reduce((acc, kpi) => {
      return acc + kpi.Weightage;
    }, 0);

    if (selectedUser?.fullName.length && selectedUser?.fullName.length > 0) {
      setReqUserErr(false);

      if (kpiInputs.length === 0 && isEdit === true) {
        confirm(selectedUser.employeeCode);
        return;
      }

      if (kpiInputs.length === 0 && isEdit === false) {
        toast.current.show({
          severity: "warn",
          summary: "Please Note!",
          detail: "Please select some kpi and weightage for saving the data.",
          life: 3000,
        });
        return;
      }

      if (isEmptySliderVal.includes(false)) {
        toast.current.show({
          severity: "warn",
          summary: "Please Note!",
          detail: "For saving the data fill all the weightage value.",
          life: 5000,
        });
        return;
      }

      if (isEmptyTargetVal.includes(false)) {
        toast.current.show({
          severity: "warn",
          summary: "Please Note!",
          detail: "For saving the data fill all the target value.",
          life: 5000,
        });
        return;
      }

      if (totalWeightage !== 100) {
        toast.current.show({
          severity: "warn",
          summary: "Please Note!",
          detail:
            "For saving the data the total weightage of kpis should be 100 otherwise you can't save the data.",
          life: 5000,
        });
        return;
      }

      const createdData: CreateKPIMappings = {
        RoleName: null,
        EmployeeCode: selectedUser?.employeeCode,
        KpiInputs: kpiInputs,
      };

      const isThereZeroVal = createdData?.KpiInputs?.filter(
        (kpIn) => kpIn.Weightage === 0
      );

      if (isThereZeroVal.length !== 0) {
        toast.current.show({
          severity: "warn",
          summary: "Please Note!",
          detail: "Weightage of kpi's can't be zero.",
          life: 4000,
        });
        return false;
      }
      await createUserMappings(dispatch, createdData);
      await getAllUserMappings();
      await getAllUsers();
      setSelectedUser(null);
      setKpiMappingsDialog(false);
      setSelectedKPI(null);
      setSliderValues([]);
      setTargetValues([]);
      setErrorIndex([]);
      setKpiList([]);
      setSearchKPI("");
      setKpis([]);
      setAllKpiToShow([]);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "You have successfully change kpi mappings!",
        life: 3000,
      });
    } else {
      setReqUserErr(true);
      toast.current.show({
        severity: "warn",
        summary: "Please Note!",
        detail: "For saving the data please select a user name.",
        life: 3000,
      });
    }
  };

  const deleteMappings = async (employeeCode: string) => {
    const deleteMappings = await deleteKpiMappingsByEmployeeCode(
      dispatch,
      employeeCode
    );
    await getAllUserMappings();
    setKpiMappingsDialog(false);
    setSelectedKPI(null);
    setSliderValues([]);
    setTargetValues([]);
    setErrorIndex([]);
    setSelectedUser(null);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "The KPIMapping is deleted successfully!",
      life: 3000,
    });
    return deleteMappings;
  };

  const reject = () => {
    setTimeout(() => {
      setKpiMappingsDialog(false);
    }, 300);
  };

  const handleSelectedUser = async (e: any) => {
    setSelectedUser(e.value);
    setReqUserErr(false);
    if (!e?.value?.roleNames) {
      setAllKpiToShow([]);
      return;
    }
    await getRoleMappings(e?.value?.roleNames);
  };

  //#endregion

  // #region all Template

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.KPIMappingWithUser || "KPI Mapping With User"}</h4>
      </div>
    );
  };

  const endToolbar = () => {
    return (
      <div className="endToolbarPerformance">
        {userPermissions.includes(createUserMapping)&&<Button
          label={localization?.Create || "Create"}
          style={{ backgroundColor: ThemeColors?.primaryColor, border: "none" }}
          onClick={handleCreate}
        />}
        <div className="searchBxCont">
          <div className="searchBx border_primary">
              <InputText
              className="w-100"
              type="search"
              placeholder={localization?.Search || "Search"}
              onChange={(e) => {
                setTimeout(() => {
                  setSearchText(e.target.value);
                }, 500);
              }}
            />
            <span className="searchIcon "><i className="pi pi-search"></i></span>
          </div>
        </div>
        
      </div>
    );
  };

  const editButtonTemplate = (rowData: any) => {
    return (
      <div className="editDeleteButtonTemplate">
        <Button
          icon="pi pi-pencil"
          // label={localization?.Edit || "Edit"}
          className="editDelButton editBtn"
          style={{ backgroundColor: ThemeColors?.primaryColor }}
          onClick={() => handleEdit(rowData)}
        />
      </div>
    );
  };

  const informationBodyTemplate = (data: UserMappingDto) => {
    const rowData = data.Kpis.map((d: Kpis) => {
      return (
        <Chip
          label={`${d?.KPI?.Name} (${
            d?.Weightage === null ? 0 : d?.Weightage
          })`}
          key={d.KPI.Name}
          style={{ margin: "2px 5px" }}
        />
      );
    });
    return rowData;
  };

  const employeeNameBodyTemp = (data: UserMappingDto) => {
    return `${data.EmployeeName}(${data.EmployeeCode})`;
  };

  const dialogHeader = () => {
    return (
      <>
        <div className="dialogHeader">
          <h4>{localization?.KPIMappings || "Kpi Mappings"}</h4>
        </div>
      </>
    );
  };

  const dialogFooter = () => {
    return (
      <div
        className="dialogFooter mt-2 pt-2"
        style={{
          paddingTop: "1rem",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
      >
        <Button
          label={localization?.Save || "Save"}
          onClick={handleSave}
          style={{ backgroundColor: ThemeColors?.primaryColor, border: "none" }}
        />
      </div>
    );
  };

  const handleInputs = () => {
    return (
      allKpiToShow.length > 0 && (
        <table className="kpiMap_table">
          <thead>
            <tr>
              <th></th>
              <th className="text-center" style={{ width: "100px" }}>
                Weightage
              </th>
              <th className="text-center" style={{ width: "100px" }}>
                Target(100%)
              </th>
            </tr>
          </thead>
          <tbody>
            {allKpiToShow?.map((kpi, index) => (
              <tr>
                <td>
                  <div className="kpi-mapp-btn">
                    <Button
                      disabled={selectedUser?.employeeCode === undefined}
                      label={kpi.Name}
                      style={{
                        backgroundColor: ThemeColors?.primaryColor,
                        border: "none",
                      }}
                      // onClick={() => handleKpiButtons(kpi, index)}
                    />
                    <i
                      className="pi pi-times-circle cross-kpi-mapp"
                      onClick={() => handleDeleteKpiMapp(kpi)}
                    ></i>
                  </div>
                </td>
                <td>
                  <div className="weightage-container py-2">
                    <InputNumber
                      disabled={selectedUser?.employeeCode === undefined}
                      inputStyle={{ width: "100%", padding: "0.6rem 1rem" }}
                      value={sliderValues[index]}
                      onChange={(e) => handleSliderChange(index, e.value)}
                      className={`w-full ${
                        errorIndex.includes(index) ? "p-invalid" : ""
                      }`}
                    />
                    <Slider
                      disabled={selectedUser?.employeeCode === undefined}
                      value={sliderValues[index]}
                      onChange={(e) => handleSliderChange(index, e.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                </td>

                <td>
                  <div className="target-container py-2">
                    <InputNumber
                      disabled={selectedUser?.employeeCode === undefined}
                      inputStyle={{ width: "100%", padding: "0.6rem 1rem" }}
                      value={targetValues[index]}
                      onChange={(e) => handleTargetSliderChange(index, e.value)}
                      className={`w-full ${
                        errorIndex.includes(index) ? "p-invalid" : ""
                      }`}
                    />
                    <Slider
                      disabled={selectedUser?.employeeCode === undefined}
                      value={targetValues[index]}
                      onChange={(e) => handleTargetSliderChange(index, e.value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    );
  };

  const selectKpiDialogHeader = () => {
    return (
      <div>
        <h4>Select the KPI</h4>
        {/* <hr /> */}
      </div>
    );
  };

  const selectKpiDialogFooter = () => {
    return (
      <Button
        className="mt-3 pt-2"
        label="Save KPI"
        onClick={saveKpis}
        style={{
          backgroundColor: ThemeColors?.primaryColor,
          border: "none",
          padding: "0.6rem 1rem",
        }}
      />
    );
  };

  const confirm = (employeeCode: string) => {
    confirmDialog({
      message:
        "If you want to save the KPIMappings without any KPI points then this particular KPI will be deleted. Are you sure you want to proceed?",
      header: "Please Note!",
      icon: "pi pi-exclamation-triangle",
      headerStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      style: {
        width: "350px",
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      contentStyle: {
        backgroundColor: mode?.backgroundSecondaryColor,
        color: mode?.color,
      },
      acceptClassName: "p-button-danger",
      accept: () => deleteMappings(employeeCode),
      reject,
    });
  };

  //#endregion

  useEffect(() => {
    getAllUserMappings();
  }, [searchText]);

  useEffect(() => {
    getAllUsers();
    getAllKpis();
  }, []);

  useEffect(() => {
    handleSearchKPI();
  }, [searckKPI]);

  return (
    <div className="user-mappings-container">
      <Toast ref={toast} />
      <ConfirmDialog />
      <Toolbar
        start={startToolbar}
        end={endToolbar}
        style={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="bg-transparent border-0 p-0 mb-3"
      />
      <div className="border_primary cs_card_shadow rounded-3 overflow-hidden"
        // style={{
        //   backgroundColor: mode?.backgroundSecondaryColor,
        //   color: mode?.color,
        // }}
      >
        <DataTable
          value={data}
          showGridlines
          paginator
          rows={row.rowCount}
          rowsPerPageOptions={row.rowList}
          className="audit-log-table userMapTable"
          currentPageReportTemplate="Total Records {totalRecords}"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            header={localization?.EmployeeName || "Employee Name"}
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={employeeNameBodyTemp}
          ></Column>
          <Column
            header={localization?.Information || "Information"}
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
            body={informationBodyTemplate}
          ></Column>
          {userPermissions.includes(editUserMapping)&&<Column
            align={'center'}
            header={localization?.Action || "Action"}
            body={editButtonTemplate}
            bodyStyle={{
              backgroundColor: mode?.backgroundSecondaryColor,
              color: mode?.color,
            }}
            headerStyle={{
              backgroundColor: ThemeColors?.primaryColor,
              color: "white",
            }}
          ></Column>}
        </DataTable>
      </div>

      <Dialog
        visible={kpiMappingsDialog}
        baseZIndex={999}
        style={{
          width: "45rem",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="KpiMap_tab kpi_create_dialog"
        onHide={hideDialog}
        header={dialogHeader}
        footer={dialogFooter}
      >
        <div className="field">
          <label className="font-bold">{localization?.User || "User"}</label>
          {showDropdown ? (
            <Dropdown
              value={selectedUser}
              onChange={handleSelectedUser}
              options={userOptions}
              showClear
              placeholder="Select the user"
              panelStyle={{
                backgroundColor: mode?.backgroundSecondaryColor,
                color: mode?.color,
              }}
              filter
            />
          ) : (
            <label className="lable-role">{labelRoleData}</label>
          )}
          {reqUserErr && (
            <small className="p-error">Please select an user</small>
          )}
        </div>
        <div className="field">
          <label className="font-bold">KPI Points</label>
          <div className="add-kpi-box">
            <p className="kpi-points-note">
              Note: Total weightage of KPI should be 100 not more than 100 or
              less than 100.
            </p>
            <Button
              className="add_kpi_btn"
              label="Add KPI"
              outlined
              onClick={handleAddKpi}
              style={{
                width: "6rem",
                backgroundColor: ThemeColors?.fontColor,
                border: `2px solid ${ThemeColors?.primaryColor}`,
                color: ThemeColors?.primaryColor,
                padding: "0.6rem 1rem",
              }}
            />
          </div>
          {handleInputs()}
        </div>
      </Dialog>

      {/* Dialog box for select KPIs **************/}
      <Dialog
        header={selectKpiDialogHeader}
        footer={selectKpiDialogFooter}
        visible={selectKpiDialog}
        onHide={hideSelectKpiDialog}
        baseZIndex={999}
        style={{
          width: "25rem",
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        contentStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        headerStyle={{
          backgroundColor: mode?.backgroundSecondaryColor,
          color: mode?.color,
        }}
        className="KpiMap_tab"
      >
        <div className="field">
          <div className="add-kpi-box">
            <span className="p-input-icon-left" style={{ width: "100%" }}>
              <i className="pi pi-search" />
              <InputText
                className="search_input"
                placeholder="Search KPI"
                value={searckKPI}
                onChange={(e) => setSearchKPI(e.target.value)}
                style={{ height: "2.8rem" }}
              />
            </span>
          </div>
          {kpis.length > 0 ? (
            <div className="searched-kpis-box">
              {kpis?.map((kpi) => {
                const isButtonAdded = kpiList.find(
                  (list) => list.id === kpi.id
                );
                const isButtonSelected = allKpiToShow.find(
                  (listKpi) => kpi.id === listKpi.id
                );
                return (
                  <Button
                    label={kpi.Name}
                    onClick={() => handleKPIList(kpi)}
                    className={
                      isButtonAdded
                        ? "selectBtn hover:bg-green-600"
                        : `non-selectBtn ${!isButtonSelected &&"text-blue-600"} hover:bg-gray-400 chouse-btn border-0`
                    }
                    style={{
                      width: "fit-content",
                      backgroundColor: isButtonSelected && "green",
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <div className="searched-kpis-box" style={{ color: "#858383" }}>
              {" "}
              Sorry! there is no KPI according to this search.
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};
