// import * as react from "react";
// import ReactApexChart from "react-apexcharts";
// import { useSelector } from "react-redux";
// import { Themes } from "../../data/Themes";
// import { ThemeState } from "../../dtos/common/ThemeState";

// interface props {
//     rating: number
// }

// export const RatingsChart = (props: props) => {
//     const themeName = useSelector((state: ThemeState) => state.theme.themeName);
//     const ThemeColors = Themes.find(th => th.name === themeName);

//         // Array of colors
//         const colors = ["#9E6DDD", "#4E9CF8", "#EA7774", "#57D175", "#D8AC04"];

//         // Pick a random color
//         const randomColor = colors[Math.floor(Math.random() * colors.length)];

//     const chart: any = {
//         series: [props.rating],
//         options: {
//             chart: {
//                 type: 'radialBar',
//                 width: 50,
//                 height: 50,
//                 sparkline: {
//                     enabled: true
//                 }
//             },
//             colors: [randomColor],
//             dataLabels: {
//                 enabled: false
//             },
//             plotOptions: {
//                 radialBar: {
//                     hollow: {
//                         margin: 0,
//                         size: '50%'
//                     },
//                     track: {
//                         margin: 0
//                     },
//                     dataLabels: {
//                         show: false
//                     },
//                 }
//             }
//         },
//     }



//     return (
//         <div>
//             <ReactApexChart options={chart.options} series={chart.series} type="radialBar" width={150} height={150} />
//         </div>
//     )
// }




import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { Themes } from "../../data/Themes";
import { ThemeState } from "../../dtos/common/ThemeState";

interface Props {
  rating: number;
  color:string;
}

export const RatingsChart = (props: Props) => {
  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find((th) => th.name === themeName);


  const chartOptions: any = {
    series: [props.rating],
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#444",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false, // Ensure the name label is hidden
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            formatter: (val: number) => `${val}%`, // Ensure only the percentage is shown
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    colors: [props.color],
  };

  

  return (
    <div id="chart">
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="radialBar"
        height={200}
      />
    </div>
  );
};
