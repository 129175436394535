// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cronjob_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.cronJobStatusTable th{
    background-color: var(--blue-highlightbtn) !important;
    color: white;
    font-size: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/css/components/cronjob.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,qDAAqD;IACrD,YAAY;IACZ,6BAA6B;AACjC","sourcesContent":[".cronjob_container {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n.cronJobStatusTable th{\n    background-color: var(--blue-highlightbtn) !important;\n    color: white;\n    font-size: inherit !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
