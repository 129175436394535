import * as react from 'react';
import ReactApexChart from 'react-apexcharts';
import { LoggedInPerformanceData } from '../../dtos/performance/LoggedInPerformanceData';

interface props {
    Data: LoggedInPerformanceData[]
}

export const LoggedInEmpPerformanceChartForTwo = (props: props) => {
    let ratings: number[] = [];
    let kpis: string[] = [];

    props?.Data.forEach(kpi => {
        ratings.push(kpi.Rating);
        kpis.push(kpi.KpiName)
    })

    const chart = {

        series: ratings,
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    offsetY: 45,
                    startAngle: 0,
                    endAngle: 270,
                    hollow: {
                        margin: 5,
                        size: '30%',
                        background: 'transparent',
                        image: undefined,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        }
                    }
                }
            },
            colors: ['#1ab7ea', '#0084ff'],
            labels: kpis,
            legend: {
                show: true,
                floating: true,
                fontSize: '15px',
                position: 'top',
                offsetX: 1,
                offsetY: -3,
                horizontalAlign:'top',
                labels: {
                    useSeriesColors: true,
                },
                markers: {
                    size: 0
                },
                formatter: function (seriesName: any, opts: any) {
                    return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                },
                // itemMargin: {
                //     vertical: 3
                // }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        show: false
                    }
                }
            }],
            tooltip: {
                theme: 'dark',
            },
        },
    };

    return (
        <div>
            <ReactApexChart options={chart.options as any} series={chart?.series} type="radialBar" height={350} />
        </div>
    )
}