import { Card } from 'primereact/card';
import { Toolbar } from 'primereact/toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeState } from '../../dtos/common/ThemeState';
import { Themes } from '../../data/Themes';
import { AppModeState } from '../../dtos/common/AppModeState';
import { AppMode } from '../../data/AppMode';
import '../../css/components/project.css';
import { getProjectByUser } from '../../service/project/ProjectService';
import { useEffect, useState } from 'react';
import { GetAllProjectItemsDto } from '../../dtos/project/GetAllProjectItemsDto';
import { GetAllProjectDto } from '../../dtos/project/GetAllProjectDto';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { GetPaginationRowsCount } from '../../shared/functions/GetPaginationRowsCount';
import { GetPaginationRowsInterface } from '../../dtos/common/GetPaginationRows';
import { Paginator } from 'primereact/paginator';
import React from 'react';
import { Dropdown } from 'primereact/dropdown';
export interface LazyState {
  page: number;
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters?: string;
}

export const Project = () => {
  //#region all Variables

  const themeName = useSelector((state: ThemeState) => state.theme.themeName);
  const ThemeColors = Themes.find(th => th.name === themeName);
  const modeName = useSelector((state: AppModeState) => state.theme.appMode);
  const mode = AppMode.find(md => md.name === modeName);
  const dispatch = useDispatch();
  const localization = useSelector((state: any) => state.localization.localization);
  const [project, setProject] = useState<GetAllProjectItemsDto[]>([]);
  const [totalProjCount, setTotalProjCount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const row: GetPaginationRowsInterface = GetPaginationRowsCount(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(row.rowCount); // Number of items to display per page
  const [sortOrder, setSortOrder] = useState<number>(0)
  const [totalOptionGroupData, setTotalOptionGroupData] = useState<number>(0);
  const [isSortClicked, setIsSortClicked] = useState<boolean>(false)
  const [lazyState, setlazyState] = useState({
    page: 0,
    first: 0,
    rows: row.rowCount,
    sortField: "createdAt",
    sortOrder: 0 as any,
  });

  //#endregion 

  //#region all Functions
  const handlePageChange = (event: any) => {
    setCurrentPage(event.page);
    setItemsPerPage(event.rows);
  };



  const getProjectsOnLoad = async () => {

    const projects: { items: GetAllProjectItemsDto[], totalCount: number } = await getProjectByUser(dispatch, {
      Limit: itemsPerPage,
      Page: currentPage,
      SearchValue: searchText?.toLowerCase(),
      Order: sortOrder
    });
    const filteredProjects: GetAllProjectItemsDto[] = projects?.items.filter(project => {
      return project.name.toLowerCase().includes(searchText.toLowerCase())
    })
    setProject(filteredProjects);
    // setTotalProjCount(projects?.result["total_count"]);
    setTotalProjCount(projects?.totalCount);
  }

  const onPageChange = (event: any) => {
    setlazyState(event);
  };




  //#endregion 

  //#region all Templates

  const template = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    RowsPerPageDropdown: (options: any) => {
      // const dropdownOptions = [
      //   { label: 4, value: 4 },
      //   { label: 8, value: 8 },
      //   { label: 12, value: 12 },
      //   { label: 16, value: 16 },
      // ];
      const dropdownOptions = row.rowList.map((item: number) => ({
        label: item,
        value: item,
      }));

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Total Records {options.totalRecords}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
  };

  const startToolbar = () => {
    return (
      <div>
        <h4>{localization?.ProjectList || 'Project List'}</h4>
        <p>{localization?.Viewallofyourprojectinformation || 'View all of your project information'}</p>
      </div>
    )
  };
console.log(ThemeColors)
  const endToolbar = () => {
    return (
      <div className="endToolbarPerformance searchBxCont" >
        <div className='searchBx border_primary'>
          <InputText className='w-100 border-0' type="search" placeholder={localization?.Search || 'Search'} onChange={(e) => {
            setTimeout(() => {
              setSearchText(e.target.value)
            }, 500)
          }} />
          <span className='searchIcon '><i className="pi pi-search"></i></span>
        </div>

      </div>
    )
  };

  //#endregion 



  useEffect(() => {
    getProjectsOnLoad()
  }, [searchText, itemsPerPage, currentPage,sortOrder])

  return (
    <div className='mb-3 me-2'>
      <Toolbar start={startToolbar} end={endToolbar} style={{ backgroundColor: mode?.backgroundSecondaryColor, color: mode?.color }} className='project-toolbar bg-transparent border-0 p-0 mb-3' />


      <div className='projectListCont TPE_lightbg rounded-3 rounded-top-0'>
        <div className='projectListHeader text-white fs-5 rounded-top-3 py-2 px-3' style={{background:ThemeColors?.secondaryColor}}>
          Name <i className={`pi ${!isSortClicked
              ? "pi-sort-alt"
              : sortOrder === 0
                ? "pi-sort-amount-down-alt"
                : "pi-sort-amount-down"
            }`} style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => {setSortOrder(sortOrder === 0 ? 1 : 0);setIsSortClicked(true)}}></i>
        </div>
        <div className='row p-4 py-3'>
          {project.length>0?project.map((pjt) => {
            return <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
              <div className='projectListSingle cs_card_shadow  d-flex gap-2 justify-content-start align-items-center bg-base rounded-3 py-2 px-3'>
                <span className='lightBg text-primary font-bold p-avatar p-component rounded-4 p-avatar-lg'>
                  <i className='pi pi-list'></i>
                </span>
                <div className="ml-2 flex-1">
                  <span className="block text-capitalize text-base line-height-2">{pjt.name}</span>
                </div>
              </div>
            </div>
          }):<>No data to show</>}
        </div>
      </div>
      <Paginator
        first={currentPage * itemsPerPage}
        rows={itemsPerPage}
        totalRecords={totalProjCount}
        onPageChange={handlePageChange}
        template={template}
        className="mt-2 "
      />

    </div>
  )
}